import { GetUserProgressResponse } from "@amzn/client-workbench-api-model";
import { trackStructEvent } from "@snowplow/browser-tracker";
import { useContext } from "react";
import { useQuery, UseQueryResult } from "react-query";

import {
  fetchUserProgress,
  VerificationStatus,
} from "@/apis/IdentityCheckHelper";
import { Logger } from "@/apis/Logger";
import { errorHandler } from "@/apis/Metrics";
import { STEP_ID } from "@/components/constants";
import {
  getCurrentRivStep,
  getLastFailedIdvType,
} from "@/components/IdentityCheck/helper/rivHelper";
import { getRetryDelayWithMax } from "@/helpers/react-query-helper";
import { SnowplowTracker } from "@/helpers/snowplow";
import { trackStructEventOnceWithPersistence } from "@/helpers/snowplow/trackStructEventHelper";
import { AppContext } from "@/stores/appStore";
import { ActionType } from "@/stores/constants";
import {
  Day1EventAction,
  Day1EventCategory,
  Day1EventProperty,
} from "@/types/snowplow-events";

/**
 * Type associated with the response from getting the accumulated wait time from local storage.
 */
type GetAccumulatedWaitingTimeCacheResponse = number | undefined;

// The key of the idb object associated with accumulated wait time
export const WAIT_START_TIME_KEY = "DocVerificationWaitStartEpochMillis";

/**
 * Set the time that the initial pending state should be set for. This is used to calculate the total amount of time that has elapsed by the time RIV is success/fail.
 */
export const setStartedWaitingEpochMillis = (): void => {
  localStorage.setItem(
    WAIT_START_TIME_KEY,
    JSON.stringify(new Date().getTime())
  );
};

/**
 * Gets the accumulated wait time that the user spent on the waiting step.
 * If the initial started waiting time is not set, returns undefined. Using undefined as a value in trackStructEvent
 *  will result in the metric being omitted.
 */
export const getAccumulatedWaitingTimeSeconds =
  (): GetAccumulatedWaitingTimeCacheResponse => {
    const storedTime = localStorage.getItem(WAIT_START_TIME_KEY);
    // use 10 as the second parameter (radix) for parseInt to convert to decimal - if not provided, it does not always default to 10
    if (storedTime && parseInt(storedTime, 10) >= 0) {
      const timestamp: GetAccumulatedWaitingTimeCacheResponse = parseInt(
        storedTime,
        10
      );
      const now = new Date().getTime();

      // Subtract times to get milli difference to determine total waiting time, convert to nearest whole second.
      return Math.round((now - timestamp) / 1000);
    }

    void Logger.warn("No timestamp set to calculate RIV wait time.");
    return undefined;
  };

/**
 * Tracks journey metrics for the current RIV status
 * @param currentRivStep current RIV step based on the user progress
 */
export const trackRivStatusMetrics = (
  currentRivStep: VerificationStatus
): void => {
  try {
    if (
      [
        VerificationStatus.LIGHT_PENDING,
        VerificationStatus.FULL_PENDING,
      ].includes(currentRivStep)
    ) {
      trackStructEvent({
        category:
          currentRivStep === VerificationStatus.LIGHT_PENDING
            ? Day1EventCategory.IDCheckLightWait
            : Day1EventCategory.IDCheckFullWait,
        action: Day1EventAction.CheckRivStatus,
        value: getAccumulatedWaitingTimeSeconds(),
      });
    } else if (
      [VerificationStatus.LIGHT_FAIL, VerificationStatus.FULL_FAIL].includes(
        currentRivStep
      )
    ) {
      // Track journey metric with the fact that the verification failed and value of the total amount of wait time.
      // This is tracked here instead of in the <UnableToVerify /> component's useEffect hook because we need to
      // create a metric which includes that the progress API says the RIV failed *and* they waited N seconds in total.
      trackStructEventOnceWithPersistence({
        category:
          currentRivStep === VerificationStatus.LIGHT_FAIL
            ? Day1EventCategory.IDCheckLightWait
            : Day1EventCategory.IDCheckFullWait,
        action: Day1EventAction.ShowRivStatus,
        property: Day1EventProperty.Fail,
        value: getAccumulatedWaitingTimeSeconds(),
      });
    } else if (
      [
        VerificationStatus.LIGHT_SUCCESS,
        VerificationStatus.FULL_SUCCESS,
      ].includes(currentRivStep)
    ) {
      // Track journey metric with the fact that the verification passed and value of the total amount of wait time.
      // This is tracked here instead of in the <VerificationCompleted /> component's useEffect hook because we need to
      // create a metric which includes that the progress API says the RIV passed *and* they waited N seconds in total.
      trackStructEventOnceWithPersistence({
        category:
          currentRivStep === VerificationStatus.LIGHT_SUCCESS
            ? Day1EventCategory.IDCheckLightWait
            : Day1EventCategory.IDCheckFullWait,
        action: Day1EventAction.ShowRivStatus,
        property: Day1EventProperty.Pass,
        value: getAccumulatedWaitingTimeSeconds(),
      });
    }
  } catch (err) {
    if (err instanceof Error) {
      errorHandler(err);
    }
  }
};

export const USER_PROGRESS_QUERY_KEY = "loadProgress";
const SIXTY_SECONDS_IN_MILLISECONDS = 60_000;

/**
 * Gets the user progress based on their verification status.
 * This function is used in both the IdentityCheck step and HomeContent through useCheckReqs().
 * @param enableMetrics boolean for if we want to track metrics (like for IdentityCheck step)
 * @param refetchInterval Refetch interval if the verification status is pending. Defaults to 60 seconds.
 */
export function useUserProgress({
  enableMetrics = false,
  refetchInterval = SIXTY_SECONDS_IN_MILLISECONDS,
} = {}): UseQueryResult<GetUserProgressResponse> {
  const { state, dispatch } = useContext(AppContext);
  /**
   * Refetch the user progress every 1 minute + (0-500ms) to avoid periodic burst. We want to keep polling the current
   * progress only if the verification status is pending for verification.
   */
  const refetchIntervalMs = [
    VerificationStatus.FULL_PENDING,
    VerificationStatus.LIGHT_PENDING,
    VerificationStatus.AUTOMATED_IDV_INFLIGHT,
  ].includes(state.docsVerificationStatus)
    ? refetchInterval + Math.floor(Math.random() * 500)
    : undefined;

  return useQuery(USER_PROGRESS_QUERY_KEY, fetchUserProgress, {
    enabled: state.amplifyConfigured,
    retryDelay: (attempt) => getRetryDelayWithMax(attempt),
    onSuccess: (data: GetUserProgressResponse) => {
      /**
       * Set the userId the personId returned by the getUserProgress API call instead of getting the userID from the
       * current auth Cognito user because new hires could have changed their PPv2 emails before onboarding through EZO
       * on their day 1. This would result in using a different Cognito user with a new userId than the one which they
       * used before and the getUserProgress API should return the previous personId in our DDB records.
       *
       * @see https://tiny.amazon.com/17vz6gc5c/quipCiaJDesi
       */
      SnowplowTracker.setUserId({
        userId: data.personId,
      });
      const manualIdvStep = getCurrentRivStep(data.verificationStatus);
      const automatedIdvProgress = data.verificationStatus.find(
        (progress) => progress.VerificationType === "AUTOMATED_IVV_VERIFICATION"
      );
      const isAutoIdvSuccess =
        automatedIdvProgress?.VerificationTypeStatus ===
        "SUBMITTED_VERIFICATION_SUCCESS";
      const isAutoIdvFailed = [
        "SUBMISSION_EXPIRED",
        "SUBMITTED_VERIFICATION_REJECTED_SUSPICIOUS",
        "SUBMITTED_VERIFICATION_REJECTED",
      ].includes(automatedIdvProgress?.VerificationTypeStatus ?? "");
      const isAutoIdvPending = [
        "SUBMITTED_VERIFICATION_PENDING",
        "SUBMISSION_PENDING",
      ].includes(automatedIdvProgress?.VerificationTypeStatus ?? "");
      const isManualIdvPending =
        manualIdvStep === VerificationStatus.FULL_PENDING ||
        manualIdvStep === VerificationStatus.LIGHT_PENDING;
      const isManualIdvFailed = [
        VerificationStatus.CONTACT_ITS,
        VerificationStatus.LIGHT_FAIL,
        VerificationStatus.FULL_FAIL,
      ].includes(manualIdvStep);

      let requiredDocsWithGesturesCount = 0;
      if (manualIdvStep === VerificationStatus.LIGHT) {
        requiredDocsWithGesturesCount = 1;
      } else if (
        manualIdvStep === VerificationStatus.FULL ||
        manualIdvStep === VerificationStatus.NOT_STARTED
      ) {
        // need to add 1 to take the challenge actions (gestures) into account
        // since data.requiredDocuments (from AppConfig) only reflects the
        // number of IDs required
        requiredDocsWithGesturesCount = data.requiredDocuments + 1;
      }

      dispatch({
        type: ActionType.SET_REQUIRED_DOCS_NUM,
        requiredDocsWithGesturesCount,
      });

      dispatch({
        type: ActionType.SET_LEGALLY_REQUIRED_DOCS_NUM,
        requiredDocsForHiringCountry: data.requiredDocuments,
      });

      // Set the hiring country to be used for the Primary ID country dropdown default
      dispatch({
        type: ActionType.SET_HIRING_COUNTRY,
        hiringCountry: data.country,
      });

      if (enableMetrics) {
        void trackRivStatusMetrics(manualIdvStep);
      }

      const noVerificationPossible = data.verificationStatus.every(
        (v) => v.EligibilityStatusCode === 1099
      );

      /**
       * Check first and second for success statuses to allow ANY successful verification to pass the verification check.
       * Note that dispatching ActionType.SET_DOCS_VERIFICATION_STATUS affects src/components/IdentityCheck/index.tsx rendering since that component is state based instead of data driven.
       */
      if (
        [
          VerificationStatus.LIGHT_SUCCESS,
          VerificationStatus.FULL_SUCCESS,
        ].includes(manualIdvStep)
      ) {
        // Set ability to move to next step.
        dispatch({
          stepId: STEP_ID.IDENTITY_CHECK,
          type: ActionType.SET_HIDE_NEXT,
          hideNext: false,
        });
        dispatch({
          type: ActionType.SET_NEXT_BTN_STATUS,
          stepId: STEP_ID.IDENTITY_CHECK,
          enableNext: true,
        });
        dispatch({
          type: ActionType.SET_DOCS_VERIFICATION_STATUS,
          docsVerificationStatus: manualIdvStep,
        });
      } else if (isAutoIdvSuccess) {
        dispatch({
          type: ActionType.SET_DOCS_VERIFICATION_STATUS,
          docsVerificationStatus: VerificationStatus.AUTOMATED_IDV_SUCCESS,
        });
      } else if (
        isManualIdvPending ||
        /**
         * Render manual IDV failed error message instead of automated since the user has completed and failed
         * the automated IDV flow.
         */
        (getLastFailedIdvType() === "automated" && isManualIdvFailed)
      ) {
        dispatch({
          type: ActionType.SET_DOCS_VERIFICATION_STATUS,
          docsVerificationStatus: manualIdvStep,
        });
      } else if (isAutoIdvPending) {
        dispatch({
          type: ActionType.SET_DOCS_VERIFICATION_STATUS,
          docsVerificationStatus: VerificationStatus.AUTOMATED_IDV_INFLIGHT,
        });
      } else if (isAutoIdvFailed) {
        dispatch({
          type: ActionType.SET_DOCS_VERIFICATION_STATUS,
          docsVerificationStatus: VerificationStatus.AUTOMATED_IDV_REJECTED,
        });
      } else if (noVerificationPossible) {
        // Case where a new hire is from a country that doesn't allow manual or automated verification,
        // user needs to contact support for IDV.
        dispatch({
          type: ActionType.SET_DOCS_VERIFICATION_STATUS,
          docsVerificationStatus: VerificationStatus.NOT_VERIFIABLE,
        });
      } else {
        // Case where automated IDV was not completed or used follows a branch to support manual RIV.
        dispatch({
          type: ActionType.SET_DOCS_VERIFICATION_STATUS,
          docsVerificationStatus: manualIdvStep,
        });
      }

      // Put dispatches last to prevent them from causing the component to rerender, thus not triggering conditional
      // statements which are below the dispatch call.
    },
    refetchInterval: refetchIntervalMs,
  });
}
