import { Container, Spacer, View } from "@amzn/stencil-react-components/layout";
import { PageContainer } from "@amzn/stencil-react-components/page";
import * as React from "react";
import { useEffect } from "react";
import { useIntl } from "react-intl";

import { ChatSupportFlyout, GetSupportFlyout } from "@/components/GetSupport";
import HomeContent from "@/components/HomeContent/HomeContent";

import { day1StepsViewClassName } from "../constants";
import Header from "../Header";

const devOrigins = ["https://0.0.0.0:8000", "https://dev.cwb.it.a2z.com:8000"];

/**
 * The Day1 OnBoarding Page which contains different steps to help new hires setting up their laptops.
 *
 * TODO: Get the current progress and determine what to display to the user based on the progress. Keeping this here as POC for using API.
 */
export default function Home(): JSX.Element {
  const intl = useIntl();

  useEffect(() => {
    if (
      // If window.opener is not null, it indicates this is a popup because the info in window.opener contains info on the parent who opened it.
      window.opener &&
      // Don't do this in development because it's irritating.
      !devOrigins.includes(window.origin)
    ) {
      window.resizeTo(window.screen.availWidth, window.screen.availHeight);
    }

    document.title = intl.formatMessage({ id: "page.title" });
    document.documentElement.lang = intl.locale;
  }, []);

  return (
    <>
      {/*Mount Chat Support flyout component in the DOM to allow it to be "resumed" instead of un/re loading the component every time it becomes invisible.*/}
      <ChatSupportFlyout />
      {/*Mount the Get Support flyout component in the DOM*/}
      <GetSupportFlyout />
      <Container isFullWidth={true} isCentered={true}>
        <Header />
        <Spacer height="S300" />
        <PageContainer>
          <View alignItems="center" className={day1StepsViewClassName}>
            <HomeContent />
          </View>
        </PageContainer>
      </Container>
    </>
  );
}
