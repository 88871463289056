import "../styles/common.scss";

import { StencilProvider } from "@amzn/stencil-react-components/context";
import { Text } from "@amzn/stencil-react-components/text";
import createCache, { EmotionCache } from "@emotion/cache";
import { useEffect, useMemo, useState } from "react";
import { IntlProvider, useIntl } from "react-intl";

import { EmbeddedMetric, Logger } from "@/apis/Logger";
import { SnowplowTracker } from "@/helpers/snowplow";
import { appOnCloseFunction } from "@/helpers/WindowEventListenerMethods";
import { useConsentLanguage } from "@/hooks/useConsentLanguage";
import { useSelectedLanguage } from "@/hooks/useSelectedLanguage";
import { loadTranslations } from "@/i18n/loadTranslations";

import { LOCALES } from "../i18n/locales";
import { ErrorBoundary } from "./ErrorBoundary";
import Home from "./Home";

const cache: EmotionCache = createCache({
  key: "nheemotioncache",
  // TODO: Generate an unique nonce every time when the page loads or use other solutions besides using nonce
  // https://sim.amazon.com/issues/NHE-928
  nonce: "NjIxOWQ1MGMtNTcxNS00YTMzLThkOTUtNTUzMjU1MjZmZjY4",
});

export default function App(): JSX.Element {
  const currentLocale = useSelectedLanguage();
  // Default to use en-US if we can't find any matching locale code

  const ivvConsentLanguage = useConsentLanguage(currentLocale);
  const [messages, setMessages] = useState<Record<string, string>>({});

  useEffect(() => {
    loadTranslations(currentLocale)
      .then((msgObj) => {
        setMessages(msgObj);
        return msgObj;
      })
      .catch((error) => {
        console.error("Failed to load translations:", error);
      });
  }, [currentLocale]);

  const localizedMessages = useMemo(
    () => ({
      ...messages,
      ...ivvConsentLanguage,
    }),
    [messages, ivvConsentLanguage]
  );

  useEffect(() => {
    try {
      window.electronAPI
        .getSystemInfo()
        .then((systemInfo) => {
          localStorage.setItem("systemInfo", JSON.stringify(systemInfo));
          return systemInfo;
        })
        .catch(() => {
          // Ignoring error since this API is only available from the EZO electron app.
        });
    } catch (error) {
      // Ignoring error since this API is only available from the EZO electron app.
    }
    Logger.publishInfoMetric(new EmbeddedMetric("AppOpened", "Count", 1));
    // Initialize an instance immediately on load.
    SnowplowTracker.instance;

    /*
     * Capture refresh or page close events before the page closes with the best effort. Note that
     * this event is not captured properly using just useEffect clean up.
     */
    window.addEventListener("beforeunload", appOnCloseFunction, { once: true });
  }, []);

  return (
    <IntlProvider
      messages={localizedMessages}
      locale={currentLocale}
      defaultLocale={LOCALES.ENGLISH}
      defaultRichTextElements={{
        bold: /* istanbul ignore next: We use a custom IntlProvider in the tests */ (
          chunk
        ) => (
          <Text fontWeight="medium" display="inline">
            {chunk}
          </Text>
        ),
        i: /* istanbul ignore next: We use a custom IntlProvider in the tests */ (
          chunk
        ) => (
          <Text fontWeight="light" display="inline">
            <i>{chunk}</i>
          </Text>
        ),
      }}
    >
      {/*
        React-Intl is broken if the StencilProvider is not wrapped by the IntlProvider.
        https://stencil.a2z.com/for-developers/onboarding/brazil#configuring-live-announcements
      */}
      <StencilProvider locale={currentLocale} emotionCache={cache}>
        <ErrorBoundary>
          <Home />
        </ErrorBoundary>
      </StencilProvider>
    </IntlProvider>
  );
}
