import english from "./en-US";
import french from "./fr-FR";
import indonesian from "./id-ID";
import { LOCALES } from "./locales";
import dutch from "./nl-NL";
import vietnamese from "./vi-VN";

const messages = {
  [LOCALES.ENGLISH]: english,
  [LOCALES.FRENCH]: french,
  [LOCALES.VIETNAMESE]: vietnamese,
  [LOCALES.DUTCH]: dutch,
  [LOCALES.INDONESIAN]: indonesian,
};

export default messages;
