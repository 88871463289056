/* istanbul ignore file */
import { Auth } from "@aws-amplify/auth";

import { STAGE, STAGES } from "@/helpers/stageConfig";

import config, { ActivatedRegions, configType } from "./amplifyStageConfig";

export const AMPLIFY_DEFAULT_API_NAME = "API";

export interface OauthConfig {
  domain: string;
  scope: Array<string>;
  redirectSignIn: string;
  redirectSignOut: string;
  responseType: string;
  options: unknown;
}

export interface AuthConfig {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  mandatorySignIn: boolean;
  cookieStorage: unknown;
  oauth: OauthConfig;
}

export interface AmplifyConfig {
  Auth: AuthConfig;
  Analytics: unknown;
  API: unknown;
}

const authDefaultOpts = {
  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: true,

  // OPTIONAL - Configuration for cookie storage
  cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    domain: `${window.location.hostname}`,

    // OPTIONAL - Cookie path
    path: "/",

    // OPTIONAL - Cookie expiration in days
    expires: 365,

    // OPTIONAL - Cookie secure flag
    secure: true,
  },
};

export const getStageConfig = (): Record<ActivatedRegions, configType> => {
  if (process.env.TEST_ENV) {
    return config[process.env.TEST_ENV as STAGES] ?? config.BETA;
  }
  return config[STAGE] ?? config.DEV;
};

export const getAmplifyConfig = (region: ActivatedRegions): AmplifyConfig => {
  const stageConfig: configType = getStageConfig()[region];
  return {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: stageConfig.USER_POOL_REGION,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: stageConfig.USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: stageConfig.USER_POOL_APP,

      ...authDefaultOpts,

      oauth: {
        domain: stageConfig.USER_POOL_DOMAIN,
        scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
        redirectSignIn: window.location.protocol + "//" + window.location.host,
        redirectSignOut: window.location.protocol + "//" + window.location.host,
        responseType: "code",
        options: {},
      },
    },

    Analytics: {
      disabled: true,
    },

    API: {
      endpoints: [
        {
          name: AMPLIFY_DEFAULT_API_NAME,
          endpoint: stageConfig.UNREGIONALIZED_API_ENDPOINT,
          custom_header: async () => {
            return {
              Authorization: `${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
      ],
    },
  };
};
