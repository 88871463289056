import { Col, Spacer, View } from "@amzn/stencil-react-components/layout";
import { H4, Text } from "@amzn/stencil-react-components/text";
import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { StepBodyStyledText } from "@/components/Commons/StepBodyStyledText";
import { ITS_LIGHT_TEAL } from "@/components/constants";
import { getOS, OPERATING_SYSTEM } from "@/helpers/os-helper";
import { useUserNotify } from "@/hooks/useUserNotify";
import { AppContext } from "@/stores/appStore";
import { ActionType } from "@/stores/constants";

import PostLoginInstructions from "./PostLoginInstructions";

/**
 * Display a view to inform the NHs that they have completed all steps required to on board with the pre-login
 * experience.
 */
export default function AllStepsCompleted(): JSX.Element {
  const { state, dispatch } = useContext(AppContext);
  const isMac = getOS() === OPERATING_SYSTEM.MAC;

  /** Inform NHs about the post-login instructions */
  const { isFetched } = useUserNotify();

  /**
   * Set the refPage to provide context to IT Support should the new hire initiate
   * a chat with support
   */
  useEffect(() => {
    dispatch({
      type: ActionType.SET_CHAT_SUPPORT_REF_PAGE,
      chatSupportRefPage: "ezo-complete",
    });
  }, []);

  /**
   * To make sure users are disconnected from any ongoing VPN connection and
   * allow them to connect to a different profile after they are done with EZO.
   *
   * Note: Will only work for the users who are using the ElectronJS app and
   * there is no effect on non-ElectronJs access.
   * @see: https://tiny.amazon.com/76g92k0q/codeamazpackDayOblob7224src
   */
  useEffect(() => {
    try {
      if (isFetched) {
        /**
         * Disconnect VPN after completing the /user/notify with some delays to
         * allow resources to be loaded completed.
         */
        const timeoutId = setTimeout(() => {
          window.electronAPI.disconnectVpn();
        }, 3_000);

        return () => clearTimeout(timeoutId);
      }
    } catch (error) {
      // Ignore since the Electron API is available on the desktop app only
    }
  }, [isFetched]);

  return (
    <View
      backgroundColor={ITS_LIGHT_TEAL}
      display="flex"
      justifyContent="start"
      alignItems="start"
      padding={{ top: "S400", bottom: "S700", left: "S700", right: "S700" }}
      dataTestId="allStepsCompletedView"
    >
      <Spacer width="S700" />
      <Col gridGap="S300">
        <H4
          color="green90"
          fontWeight="medium"
          dataTestId="allStepsCompletedTitle"
        >
          <FormattedMessage id="allStepsCompleted-title" />
        </H4>
        <StepBodyStyledText>
          <Text fontWeight="medium" dataTestId="allStepsCompletedNextStep">
            <FormattedMessage
              id={
                isMac
                  ? "allStepsCompleted-mac-nextTo"
                  : "allStepsCompleted-win-nextTo"
              }
            />
          </Text>
          <PostLoginInstructions username={state.amazonUsername} os={getOS()} />
        </StepBodyStyledText>
      </Col>
    </View>
  );
}
