import { UserVerificationProgress } from "@amzn/client-workbench-api-model";

import { VerificationStatus } from "@/apis/IdentityCheckHelper";
import { Logger } from "@/apis/Logger";

const isProgressStatusValid = ({
  lightProgress,
  fullProgress,
  autoIdvProgress,
}: {
  lightProgress: UserVerificationProgress;
  fullProgress: UserVerificationProgress;
  autoIdvProgress: UserVerificationProgress;
}) => {
  return (
    (lightProgress.VerificationTypeStatus !== "NOT_APPLICABLE" &&
      fullProgress.VerificationTypeStatus === "NOT_APPLICABLE") ||
    (lightProgress.VerificationTypeStatus === "NOT_APPLICABLE" &&
      fullProgress.VerificationTypeStatus !== "NOT_APPLICABLE") ||
    autoIdvProgress.VerificationTypeStatus === "VERIFICATION_ELIGIBLE"
  );
};

/**
 * Returns a VerificationStatus based on the response from the API with the user progress API.
 * Valid status: "VERIFICATION_ELIGIBLE" | "NOT_APPLICABLE" | "VERIFICATION_COMPLETE" |
 * "SUBMITTED_VERIFICATION_PENDING" | "SUBMISSION_PENDING" | "SUBMISSION_EXPIRED"
 * @param progress Progress data from API.
 */
export const getCurrentRivStep = (
  progress: UserVerificationProgress[]
): VerificationStatus => {
  const lightProgress = progress.find(
    (p) => p.VerificationType === "ON_BOARDING_LIGHT_VERIFICATION"
  );
  const fullProgress = progress.find(
    (p) => p.VerificationType === "ON_BOARDING_FULL_VERIFICATION"
  );
  const autoIdvProgress = progress.find(
    (p) => p.VerificationType === "AUTOMATED_IVV_VERIFICATION"
  );

  // Check if we have all the data to make a decision.
  if (!lightProgress || !fullProgress || !autoIdvProgress) {
    void Logger.error(
      "Could not parse the verification type from the verification API response. Returning NOT_STARTED.",
      undefined,
      { progress }
    );
    return VerificationStatus.NOT_STARTED;
  } else if (
    isDayOneForbidden(progress) ||
    !isProgressStatusValid({ lightProgress, fullProgress, autoIdvProgress })
  ) {
    return VerificationStatus.CONTACT_ITS;
  }

  const isLight =
    lightProgress.VerificationTypeStatus !== "NOT_APPLICABLE" &&
    fullProgress.VerificationTypeStatus === "NOT_APPLICABLE";
  const verificationStatus = isLight
    ? lightProgress.VerificationTypeStatus
    : fullProgress.VerificationTypeStatus;

  switch (verificationStatus) {
    case "SUBMITTED_VERIFICATION_REJECTED":
    case "SUBMISSION_EXPIRED":
      return isLight
        ? VerificationStatus.LIGHT_FAIL
        : VerificationStatus.FULL_FAIL;
    case "SUBMITTED_VERIFICATION_SUCCESS":
      return isLight
        ? VerificationStatus.LIGHT_SUCCESS
        : VerificationStatus.FULL_SUCCESS;
    case "SUBMITTED_VERIFICATION_PENDING":
      return isLight
        ? VerificationStatus.LIGHT_PENDING
        : VerificationStatus.FULL_PENDING;
    case "SUBMISSION_PENDING":
    case "VERIFICATION_ELIGIBLE":
      return isLight ? VerificationStatus.LIGHT : VerificationStatus.FULL;
    default:
      if (autoIdvProgress.VerificationTypeStatus === "VERIFICATION_ELIGIBLE") {
        // We return the full verification in this case because we still want the new hire to see the page where AutoIDV is described
        // and with the Continue button leading to the biometrics consents page.
        // If we returned AUTOMATED_IDV_INFLIGHT, this would go straight into biometrics without consent or description.
        return VerificationStatus.FULL;
      }
      void Logger.error(
        `Unhandled verification status '${verificationStatus}'. Returning CONTACT_ITS.`
      );
      return VerificationStatus.CONTACT_ITS;
  }
};

/**
 * Check if NHs is eligible for starting Day1 onboarding by checking if both full & light RIV statuses are
 * NOT_APPLICABLE. We will retire this method once we get a 403 error from the /user/progress API if NHs are
 * ineligible to start their day 1 onboarding.
 * @param progress Progress data from API.
 */
export const isDayOneForbidden = (
  progress: UserVerificationProgress[]
): boolean => {
  const lightProgress = progress.find(
    (p) => p.VerificationType === "ON_BOARDING_LIGHT_VERIFICATION"
  );
  const fullProgress = progress.find(
    (p) => p.VerificationType === "ON_BOARDING_FULL_VERIFICATION"
  );
  const autoIdvProgress = progress.find(
    (p) => p.VerificationType === "AUTOMATED_IVV_VERIFICATION"
  );

  if (!(fullProgress && lightProgress && autoIdvProgress)) {
    void Logger.error(
      "Cannot parse progress, returning Day One is Forbidden. VerificationType must have ON_BOARDING_LIGHT_VERIFICATION, ON_BOARDING_FULL_VERIFICATION, and AUTOMATED_IVV_VERIFICATION keys.",
      undefined,
      { progress }
    );
    return true;
  }

  /**
   * They're forbidden if their VerificationTypeStatus is 'NOT_APPLICABLE' unless
   * all the EligibilityStatusCodes are 1099, in which case they're not forbidden,
   * there just isn't support for their country at the moment. They'll be presented
   * with a message to contact support as a part of the Identity Verification step.
   * They also will not be forbidden if they are eligible only for Auto IDV.
   */
  return (
    fullProgress.VerificationTypeStatus === "NOT_APPLICABLE" &&
    lightProgress.VerificationTypeStatus === "NOT_APPLICABLE" &&
    autoIdvProgress.VerificationTypeStatus !== "VERIFICATION_ELIGIBLE" &&
    !progress.every((v) => v.EligibilityStatusCode === 1099)
  );
};

export const FAILED_IDV_TYPE = "lastFailedIdvType";

/**
 * Set the last failed IDV type in the localStorage if there is none set yet. This is used for deciding which verification
 * failure message to render if users have failed manual and automated IDVs.
 *
 * @param idvType Manual or Automated IDV
 */
export const setLastFailedIdvType = (idvType: "manual" | "automated"): void => {
  if (!getLastFailedIdvType()) {
    localStorage.setItem(FAILED_IDV_TYPE, idvType);
  }
};

/**
 * Get the last failed IDV type in the localStorage if there is none set yet. This is used for deciding which verification
 * failure message to render if users have failed manual and automated IDVs.
 */
export const getLastFailedIdvType = (): string | undefined => {
  const lastFailedIdvType = localStorage.getItem(FAILED_IDV_TYPE);
  return lastFailedIdvType ? lastFailedIdvType.toLocaleLowerCase() : undefined;
};

/**
 * True if the user have failed automated IDV before. Otherwise, return false.
 */
export const hasFailedAutomatedIdv = (): boolean =>
  getLastFailedIdvType() === "automated";

/**
 * True if the user have failed manual IDV before. Otherwise, return false.
 */
export const hasFailedManualIdv = (): boolean =>
  getLastFailedIdvType() === "manual";
