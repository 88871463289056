import { languageObject } from "./locales";

/**
 * This locale file will be used by the application to change the language to Dutch
 * based on user selection.
 **/

const dutch: languageObject = {
  "page.title": "Amazon dag 1",
  "button.next": "Volgende",
  "button.back": "Terug",
  "button.done": "Klaar",
  "text.then": "Dan",
  "text.chatWithITSupport": "Chat met IT-ondersteuning",
  "videoGuidance.title": "Videobegeleiding",
  "videoGuidance.example": "Voorbeeld",
  "videoGuidance.button.altText": "Videobegeleiding tonen",
  "icon.title": "Voltooid",
  "error.notSupportVideoTag": "Je browser ondersteunt de videotag niet.",
  "welcome.expander.title": "Welkom bij Amazon 🎉",
  "welcome.expander.body.greeting":
    "We zijn blij dat je er bent. We begeleiden je bij het installeren van je nieuwe laptop.",
  "welcome.expander.body.requirements.start": "Wat heb je nodig:",
  "welcome.expander.body.requirements.list1":
    "Je beveiligingssleutel hebben we naar je gemaild.",
  "welcome.expander.body.requirements.list2": "Een wifi-verbinding.",
  "welcome.expander.body.requirements.list3":
    "De gebruikersnaam en het wachtwoord van uw Embark Pre-Boarding-portaal.",
  "welcome.expander.body.requirements.list4":
    "Toegang tot uw persoonlijke e-mail om tijdens het hele proces aanvullende informatie te ontvangen.",
  "welcome.expander.body.firstStep.start":
    "Eerste stap: Maak verbinding met wifi",
  "welcome.expander.body.firstStep.list1":
    "Klik op het wifiicoontje op de taakbalk.",
  "welcome.expander.body.firstStep.list2": "Selecteer je wifi-verbinding.",
  "welcome.expander.body.firstStep.list3":
    "Voer je wifi-wachtwoord in en klik op ”Connect”.",
  "welcome.button.getStarted": "Ga aan de slag",
  "identityCheck.expander.title": "Identiteitsverificatie",
  "identityCheck.expander.body.header": "Video's opnemen",
  "identityCheck.expander.body.intro":
    "Neem video's op terwijl je je identiteitsdocumenten naast je gezicht houdt en voer een reeks handgebaren uit. Stuur deze video's naar een Amazon Human Verifier om ze te beoordelen.",
  "identityCheck.expander.body.recordInfo.title":
    "Informatie over ”Video's opnemen”",
  "identityCheck.expander.body.recordInfo.body":
    "Voordat Amazon toegangsgegevens voor het Amazon-systeem verstrekt, moet Amazon je identiteit verifiëren. Amazon maakt hiervoor gebruik van een identiteitsverificatiesysteem (”Systeem”). Het gebruik van het systeem is volledig optioneel en vrijwillig. Het systeem werkt als volgt: upload een foto van een door de overheid uitgegeven identiteitsbewijs met foto (”Govt. Foto-ID”) en neem een video op van jezelf terwijl je je identiteitsbewijs met foto vasthoudt. De naam op het identiteitsbewijs met foto moet in grote lijnen lijken op de naam die Amazon voor je heeft geregistreerd. Een van onze ondersteuningsmedewerkers verifieert uw naam en foto op uw identiteitsbewijs met uw gezicht in de ingezonden opname.",
  "identityCheck.expander.body.whatIsCollected.title":
    "Welke informatie wordt verzameld",
  "identityCheck.expander.body.whatIsCollected.intro":
    "Om je identiteit te verifiëren, verzamelt Amazon de volgende persoonlijke informatie van je:",
  "identityCheck.expander.body.whatIsCollected.item1":
    "Foto ('s) van uw identiteitsbewijs met foto van de overheid",
  "identityCheck.expander.body.whatIsCollected.item2":
    "Een video-selfie van jou",
  "identityCheck.expander.body.whatIsCollected.purpose":
    "Uw persoonlijke informatie wordt uitsluitend gebruikt voor identificatie- en verificatiedoeleinden. Amazon zal alle persoonlijke informatie binnen 7 dagen na het voltooien van het verificatieproces vernietigen, tenzij het wettelijk verplicht is om je persoonlijke gegevens voor een langere periode te bewaren. Raadpleeg voor meer informatie de privacyverklaring van Amazon HR, die is verstrekt als onderdeel van je onboardingpakket, of via het interne Amazon-portaal, AtoZ, nadat het installatieproces van de laptop is voltooid.",
  "identityCheck.expander.body.light.instruction":
    "Zodra je met de ID-gebarenuitdaging bent begonnen, heb je <b>10 minuten</b> om een enkele <b>video van 10 seconden</b> op te nemen en in te dienen waarin je de gebaren voltooit.",
  "identityCheck.expander.body.helpLinkPre":
    "Lukt het niet om de acties te voltooien? Gelieve {chatWithITSupportLink} om je identiteit te verifiëren tijdens een Chime-videogesprek.",
  "identityCheck.expander.body.helpLinkPost":
    "om je identiteit te verifiëren tijdens een Chime-videogesprek.",
  "identityCheck.challenges.title": "Uitdagende acties",
  "identityCheck.challenges.completed":
    "Je uitdagingsacties zijn geregistreerd.",
  "identityCheck.challenges.instructions.completeByTime":
    "Voltooi deze uitdaging alstublieft vóór {time}",
  "identityCheck.challenges.instructions":
    "Volg de instructies om een enkele video van 10 seconden op te nemen door deze twee bewegingen uit te voeren:",
  "identityCheck.challenges.expired":
    "<b>Je tijdslimiet is verstreken. </b>Alsjeblieft {chatWithIt}. U wordt gevraagd deel te nemen aan een videogesprek en uw ID te tonen aan een IT-ondersteuningsingenieur.",
  "identityCheck.challenges.instructions.unhideButton":
    "Begin met de gebarenuitdaging",
  "identityCheck.challenges.forSeconds": "gedurende {second} seconden.",
  "identityCheck.button.recording": "Opnemen: {countDownTimer}",
  "identityCheck.button.startRecord": "Opname starten",
  "identityCheck.button.enableCamera": "Camera inschakelen",
  "identityCheck.button.retry": "Probeer het opnieuw",
  "identityCheck.do.title": "Do's",
  "identityCheck.do.hint1": "Kijk recht in de camera.",
  "identityCheck.do.hint2": "Gebruik de juiste blootstelling aan licht.",
  "identityCheck.dont.title": "Don's",
  "identityCheck.dont.hint1":
    "Bedek je identiteitsbewijs met je vingers of hand.",
  "identityCheck.dont.hint2": "Bedek je gezicht met een masker.",
  "identityCheck.dont.hint3":
    "Draag een zonnebril (een bril op sterkte is oké).",
  "identityCheck.dont.hint4":
    "Draag hoeden of petten (religieuze hoofddeksels die je gezicht niet bedekken, is oké).",
  "identityCheck.full.steps.recordPrimaryId":
    "Video opnemen met {requiredDocuments, plural, one {overheid} other {primair}} ID",
  "identityCheck.full.steps.recordSecondaryId":
    "Video opnemen met een secundair identiteitsbewijs",
  "identityCheck.full.steps.secondaryIdGovIssued":
    "Is uw secundaire identiteitskaart door de overheid uitgegeven?",
  "identityCheck.secondaryIdGovIssued.instruction":
    "Secundair identiteitsbewijs kan een niet-overheidsdocument zijn met uw naam en foto. Breng dit mee op je eerste dag.",
  "identityCheck.full.steps.recordChallengeActions":
    "Opnemen van uitdagingsacties",
  "identityCheck.full.primaryId.expandAltText":
    "Klik om {requiredDocuments, plural, one {government} other {primary}} Id te uploaden",
  "identityCheck.full.secondaryId.expandAltText":
    "Klik om een tweede ID te uploaden",
  "identityCheck.full.challengeActions.expandAltText":
    "Klik om uitdagingsacties te uploaden",
  "identityCheck.primaryId.instruction":
    "Neem een video van 15 seconden op met je fysieke {requiredDocuments, plural, one {overheid} other {primair}} ID naast je gezicht en dan tegen de camera. Dit ID moet een door de overheid uitgegeven identiteitsbewijs zijn dat nog niet is verlopen uit deze lijst van geaccepteerde documenten. Het moet uw naam, geboortedatum en foto bevatten.",
  "identityCheck.secondaryId.instruction":
    "Neem een video van 15 seconden op met je fysieke secundaire ID naast je gezicht en vervolgens tegen de camera. Het secundaire identiteitsbewijs kan een ander door de overheid uitgegeven identiteitsbewijs zijn (bijvoorbeeld een rijbewijs) of een niet door de overheid uitgegeven identiteitsbewijs met foto (bijvoorbeeld een Costco-kaart of een studentenkaart).",
  "identityCheck.primaryIdCountry.select.label":
    "Welk land heeft je identiteitsbewijs uitgegeven?",
  "identityCheck.primaryId.select.label": "ID-type",
  "identityCheck.primaryId.select.placeholder": "Selecteer het ID-type",
  "identityCheck.primaryId.country.select.placeholder":
    "Selecteer het land dat de ID uitgeeft",
  "identityCheck.primaryId.video.instruction":
    "Houd je {requiredDocuments, plural, one {Government} other {Primary}} ID naast je gezicht en beweeg het naar de camera.",
  "identityCheck.primaryId.video.instruction2":
    "Houd je ID 3 cm van de camera verwijderd totdat deze scherp is.",
  "identityCheck.primaryId.completed":
    "Je primaire ID en gezicht zijn geregistreerd.",
  "identityCheck.governmentId.completed":
    "Je overheidsidentiteit en gezicht zijn geregistreerd.",
  "identityCheck.secondaryId.completed":
    "Je secundaire ID en gezicht zijn geregistreerd.",
  "identityCheck.primary.title":
    "Video opnemen met uw {requiredDocuments, plural, one {overheid} other {primary}} ID",
  "identityCheck.secondaryID.governmentID.radio1":
    "Door de overheid uitgegeven identiteitsbewijs",
  "identityCheck.secondaryID.governmentID.radio2":
    "Niet door de overheid uitgegeven identiteitsbewijs",
  "identityCheck.secondaryId.title": "Video opnemen met je secundaire ID",
  "identityCheck.secondaryId.video.instruction":
    "Houd je secundaire ID naast je gezicht en beweeg deze naar de camera.",
  "identityCheck.secondaryId.video.instruction2":
    "Houd je ID 3 cm van de camera verwijderd totdat deze scherp is.",
  "identityCheck.button.goBackAltText": "Ga terug",
  "identityCheck.button.submitReview": "Ter beoordeling indienen",
  "identityCheck.button.loading": "Inzenden...",
  "identityCheck.button.tooltip.disallowResubmit":
    "De video-opname is al ingediend.",
  "identityCheck.button.tooltip.missingPrimaryIdType":
    "Selecteer een {requiredDocuments, plural, one {government} other {primary}} ID-type in de keuzelijst.",
  "identityCheck.button.tooltip.missingSecondaryIdGovIssued":
    "Selecteer met behulp van de keuzerondjes of uw secundaire ID-type door de overheid is uitgegeven.",
  "identityCheck.errors.unableToVerify":
    "We konden je identiteit niet verifiëren. Alsjeblieft {link}. Je wordt gevraagd deel te nemen aan een videogesprek en je ID te tonen aan een medewerker van Amazon IT Support, en je krijgt een andere manier om je laptop in te stellen.",
  "identityCheck.errors.unableToVerifyFallback":
    "<b>We konden je identiteit niet verifiëren met behulp van handmatige identiteitsverificatie. </b>Probeer in plaats daarvan <a>biometrische identiteitsverificatie te</a> gebruiken.",
  "identityCheck.errors.failedToSubmit":
    "We konden je video niet insturen. Probeer het over een paar seconden opnieuw of {link} voor verdere hulp.",
  "identityCheck.errors.chatWithIT": "Chat met IT-ondersteuning",
  "identityCheck.pending.pleaseWait":
    "Terwijl je video's worden geverifieerd, ga alsjeblieft niet weg van deze pagina.",
  "identityCheck.pending.estimatedWaitTime":
    "De typische wachttijd voor identiteitsverificatie is {minTimeMin} tot {maxTimeMin} minuten.",
  "identityCheck.verified.successMsg": "We hebben je identiteit geverifieerd.",
  "identityCheck.ivv.body.intro":
    "Voor het instellen van je Amazon-gebruikersnaam en -wachtwoord is een extra beveiligingslaag vereist om te bewijzen dat je jezelf bent, en niet iemand die zich voordoet als jezelf.",
  "identityCheck.ivv.body.consentcontent.agreebtnrequired":
    "Vink beide vakjes hierboven aan en klik op {agree} om te bevestigen dat je het hebt gelezen en begrepen, of klik op Annuleren om je identiteit handmatig te verifiëren.",
  "identityCheck.ivv.failedBody":
    "<b>We konden je identiteit niet verifiëren met een geautomatiseerde gezichtsscan. </b><a>Neem video's</a> op terwijl u uw identiteitsdocumenten naast uw gezicht houdt en voer een reeks handgebaren uit.",
  "identityCheck.ivv.failedBodyNoFallback":
    "<b>We konden je identiteit niet verifiëren met een geautomatiseerde gezichtsscan. </b>{chatWithITSupportLink} voor verdere hulp.",
  "identityCheck.ivv.inFlight":
    "Je hebt al een verificatie tijdens de vlucht en je kunt geen nieuwe verificatie starten. Vernieuw de pagina regelmatig om de status te controleren.",
  "identityCheck.ivv.cantLoadWidget":
    "<b>Ons systeem is uitgevallen. </b>Gelieve te verversen. Als je niet verder kunt gaan. Alsjeblieft {chatWithITSupportLink}.",
  "identityCheck.ivv.pendingBackendAgreementPre":
    "<b>Je identiteitscontrole afronden</b>",
  "identityCheck.ivv.pendingBackendAgreement":
    "Dit kan enkele minuten duren. Als je langer dan 10 minuten hebt gewacht, gebruik dan de knop ”Ondersteuning krijgen” voor hulp.",
  "identityCheck.ivv.scanAbortHeader":
    "Heb je problemen met je automatische scan?",
  "identityCheck.ivv.scanAbortBody":
    "Geen probleem. Je kunt in plaats daarvan ons <openModal>alternatieve verificatieproces</openModal> gebruiken.",
  "identityCheck.ivv.scanAbortModalHeader":
    "Weet je zeker dat je een alternatief verificatieproces wilt gebruiken?",
  "identityCheck.ivv.scanAbortModalBody":
    " <p>Als u hebt besloten de automatische biometrische scan over te slaan. Om je te laten weten, je kunt daarna niet meer teruggaan en de gezichtsscan gebruiken. </p><p>Het alternatieve verificatieproces kan iets langer duren, maar ons team zal je op de eerste dag begeleiden bij alles wat je nodig hebt om je laptop in te stellen. </p>",
  "identityCheck.ivv.scanAbortModalCancel": "Annuleer",
  "identityCheck.ivv.scanAbortModalConfirm": "Bevestigen",
  "identityCheck.pathchooser.title":
    "Selecteer je verificatievoorkeur op een van de volgende manieren.",
  "identityCheck.pathchooser.card.recommended": "Aanbevolen",
  "identityCheck.pathchooser.card.estCompletionAutomated":
    "Tijd om te voltooien: <b>Minder dan {count} {unit}</b>",
  "identityCheck.pathchooser.card.estCompletionManual":
    "Tijd om te voltooien: <b>{count} {unit}</b>",
  "identityCheck.pathchooser.card.unitMinute": "minuut",
  "identityCheck.pathchooser.card.unitMinutes": "minuten",
  "identityCheck.pathchooser.card.verificationTypeAutomated":
    "Verificatietype: <b>Geautomatiseerd</b>",
  "identityCheck.pathchooser.card.verificationTypeManual":
    "Verificatietype: <b>Handmatig</b>",
  "identityCheck.pathchooser.card.oneId":
    "ID vereist: <b>1 door de overheid uitgegeven identiteitsbewijs</b>",
  "identityCheck.pathchooser.card.twoIdsAutomated":
    "Vereiste identiteitsbewijzen: <b>2 door de overheid uitgegeven identiteitsbewijzen</b>",
  "identityCheck.pathchooser.card.twoIdsManual":
    "Vereiste identiteitsbewijzen: <b>1 door de overheid uitgegeven identiteitsbewijs, 1 secundair identiteitsbewijs</b>",
  "identityCheck.pathchooser.card.errorSelectionRequired":
    "Er moet een verificatietype worden geselecteerd",
  "identityCheck.pathchooser.card.errorAnyInFlight":
    "Er is al een verificatie tijdens de vlucht",
  "identityCheck.pathchooser.card.ivv.title": "Geautomatiseerde gezichtsscan",
  "identityCheck.pathchooser.card.ivv.body":
    "Scan je gezicht op je eerste dag en we vergelijken het met de foto op je door de overheid uitgegeven identiteitsbewijs. Je identiteit wordt onmiddellijk bevestigd en je bent klaar om te vertrekken.",
  "identityCheck.ivv.countDownTimer":
    "resterend totdat het alternatieve verificatieproces wordt gestart (”door mensen geverifieerde beoordeling”)",
  "identityCheck.pathchooser.card.manual.title": "Video's opnemen",
  "identityCheck.pathchooser.card.manual.body":
    "Neem video's op terwijl je je identiteitsdocumenten naast je gezicht houdt en voer een reeks handgebaren uit. Stuur deze video's naar een menselijke verificateur van Amazon om ze te beoordelen.",
  "identityCheck.pathchooser.card.contactsupport.title":
    "Contact opnemen met de klantenservice",
  "identityCheck.pathchooser.card.contactsupport.body":
    "Chat in realtime met een IT-ondersteuningsmedewerker en verifieer je identiteit via een videoconferentie op Amazon Chime, die je door een handmatig installatieproces leidt.",
  "identityCheck.pathchooser.additionalConcerns":
    "Als je problemen ondervindt of nog andere zorgen hebt, neem dan contact op met ons IT-ondersteuningsteam.",
  "identityCheck.pathchooser.additionalConcerns.contactIt":
    "Zij kunnen u via een beveiligd videogesprek helpen om uw identiteit te verifiëren. {chatWithITSupportLink}.",
  "identityCheck.pathchooser.additionalConcerns.chimeVerificationType":
    "Verificatietype: <b>Videoconferentiegesprek</b>",
  "identityCheck.pathchooser.submitBtn": "Verzenden",
  "identityCheck.pathchooser.continueBtn": "Ga verder",
  "amazonUsername.expander.title": "Amazon-gebruikersnaam",
  "amazonUsername.expander.body.info":
    "Bij Amazon gebruik je je gebruikersnaam om in te loggen op je computer en toegang te krijgen tot tools en bronnen van Amazon. Dit is het deel van je Amazon-e-mailadres dat vóór de @ staat. Je e-mailadres is {emailAddress}.",
  "amazonUsername.expander.body.username":
    "Je Amazon-gebruikersnaam is: {username}",
  "registerSecurityKey.expander.title": "Beveiligingssleutel registreren",
  "registerSecurityKey.expander.body.intro":
    "Bij Amazon gebruiken we je beveiligingssleutel als stap in meervoudige authenticatie om er zeker van te zijn dat jij het bent. Je wordt dagelijks gevraagd om op je beveiligingssleutel te drukken om toegang te krijgen tot het Amazon Network. Elke keer dat u op uw toets drukt, wordt een willekeurige reeks tekens gegenereerd.",
  "registerSecurityKey.expander.body.securityKeyTitle":
    "Wat is een beveiligingssleutel?",
  "registerSecurityKey.expander.body.securityKeyDescription1":
    " plastic apparaat dat wordt gebruikt om veilig toegang te krijgen tot Amazon-bronnen. Wanneer je Amazon-laptop naar je is verzonden, we hebben je ook twee USB-beveiligingssleutels gestuurd. De tweede sleutel is uw reservesleutel - bewaar deze op een veilige plaats voor het geval uw primaire sleutel verloren of beschadigd raakt.",
  "registerSecurityKey.expander.body.securityKeyDescription2":
    "Bij Amazon gebruiken we je beveiligingssleutel als stap in meervoudige authenticatie om er zeker van te zijn dat je jezelf bent. Als je elke dag veel van de interne tools en bronnen van Amazon wilt gebruiken, voer je je wachtwoord en je beveiligingssleutel in om toegang te krijgen tot het Amazon-netwerk. Kies een pincode voor de beveiligingssleutel die gemakkelijk te onthouden is. Je typt het elke dag wanneer je inlogt bij interne Amazon-services en VPN. Het vervalt niet.",
  "registerSecurityKey.expander.body.midwayTitle": "Wat is Midway?",
  "registerSecurityKey.expander.body.midwayDescription":
    "Midway is de service die beveiligingssleutels en pincodes met beveiligingssleutels beheert.",
  "registerSecurityKey.expander.body.howTitle":
    "Hoe registreer ik mijn beveiligingssleutel?",
  "registerSecurityKey.expander.body.step1":
    "Stuur een tijdelijke pincode naar je persoonlijke e-mailadres om toegang te krijgen tot Midway. Open je persoonlijke e-mailadres om toegang te krijgen tot deze tijdelijke pincode.",
  "registerSecurityKey.expander.body.pinSentConfirmation":
    "We hebben een verificatiecode naar je persoonlijke e-mailadres gestuurd. Je pincode verloopt over 4 uur. Geen code ontvangen? {resendLink}",
  "registerSecurityKey.expander.body.step2":
    "Zodra je de tijdelijke pincode hebt, registreer je je sleutel op Midway. Je wordt gevraagd je Amazon-gebruikersnaam en je tijdelijke pincode in te voeren.",
  "registerSecurityKey.expander.body.step3":
    "Steek een van uw beveiligingssleutels in een USB-poort van deze laptop. Mogelijk moet u 1 van de adapters gebruiken die u hebt ontvangen. (Je hoeft maar 1 van je beveiligingssleutels te registreren. De tweede sleutel is een back-up.) Steek deze sleutel voorzichtig in met het gaatje naar buiten gericht. Je ziet een groen lampje wanneer deze beveiligingssleutel correct is geplaatst.",
  "registerSecurityKey.expander.body.step4":
    "Voer op de aanmeldingspagina het volgende in:",
  "registerSecurityKey.expander.body.step4.1":
    "<bold>Amazon-gebruikersnaam:</bold> de gebruikersnaam die in de vorige stap is weergegeven.",
  "registerSecurityKey.expander.body.step4.2":
    "<bold>Pincode (of pincode met beveiligingssleutel):</bold> de tijdelijke pincode die naar uw persoonlijke e-mailadres wordt gestuurd.",
  "registerSecurityKey.expander.body.step5":
    "Nadat u uw gebruikersnaam en tijdelijke pincode voor de beveiligingssleutel hebt ingevoerd, klikt <bold>u op Aanmelden</bold>:",
  "registerSecurityKey.expander.body.step6":
    'Je ziet een bericht met de tekst "Welkom {username}!',
  "registerSecurityKey.expander.body.step7":
    "Klik op de link voor het <bold>registratieportaal voor beveiligingssleutels</bold> in het welkomstbericht om door te gaan.",
  "registerSecurityKey.expander.body.step8":
    "Klik op de pagina met de tekst 'Welkom bij het dashboard met beveiligingssleutels' op <bold>Beveiligingssleutel registreren</bold>.",
  "registerSecurityKey.expander.body.step9":
    "Klik op de pagina met de tekst 'Aan de slag met uw beveiligingssleutel' op <bold>Registratie starten</bold>. Als u hier twee opties ziet, kiest u Begin met de registratie van de hardwarebeveiligingssleutel.",
  "registerSecurityKey.expander.body.step10":
    "Selecteer de afbeelding die overeenkomt met de beveiligingssleutel die je hebt ontvangen.",
  "registerSecurityKey.expander.body.step11":
    "Vervolgens voer je het eenmalige wachtwoord (OTP) in dat door je beveiligingssleutel is gegenereerd. Klik in het lege tekstvak met de tekst ”Je OTP verschijnt hier, typ niet in dit vak”.",
  "registerSecurityKey.expander.body.step12.1":
    "Druk op de beveiligingssleutel die op uw computer is aangesloten. Oefen gedurende 3-5 seconden lichte druk uit totdat er een reeks tekst in het lege tekstvak verschijnt. Dit is de OTP. Afhankelijk van het type beveiligingssleutel dat je hebt, moet je lichte druk uitoefenen op de <bold>zijkant</bold> of <bold>bovenkant</bold>.",
  "registerSecurityKey.expander.body.step12.2":
    "<bold>Als u uw beveiligingssleutel indrukt maar geen OTP invoert</bold>, verwijdert u uw vinger van de beveiligingssleutel, klikt u op het tekstvak en raakt u de beveiligingssleutel opnieuw aan.",
  "registerSecurityKey.expander.body.step13":
    "Typ de permanente pincode voor de beveiligingssleutel die u wilt gebruiken in de velden <bold>Nieuwe pincode</bold> en <bold>Bevestig nieuwe pincode</bold> en klik vervolgens op <bold>Doorgaan</bold>.",
  "registerSecurityKey.expander.body.step13.1":
    "Typ de permanente pincode voor de beveiligingssleutel die u wilt gebruiken in de velden <bold>Nieuwe pincode</bold> en <bold>Bevestig nieuwe pincode</bold> en klik vervolgens op <bold>Doorgaan</bold>.",
  "registerSecurityKey.expander.body.step13.2":
    "<bold>Belangrijk</bold>: Kies een pincode voor de beveiligingssleutel die gemakkelijk te onthouden is. Je typt het elke dag wanneer je inlogt bij interne Amazon-services en VPN. Het vervalt niet.",
  "registerSecurityKey.expander.body.step14":
    "Op de pagina met de tekst ”Lees alstublieft alle onderstaande instructies... ”druk op <bold>Doorgaan</bold>.",
  "registerSecurityKey.expander.body.step15":
    "Houd op de volgende pagina de beveiligingssleutel opnieuw ingedrukt. Het kan zijn dat je een pop-up krijgt met informatie over je beveiligingssleutel. Klik op <bold>Toestaan</bold>.",
  "registerSecurityKey.expander.body.screenPossibilityListTitle":
    "Vervolgens zie je 1 van de 2 schermen:",
  "registerSecurityKey.expander.body.screenPossibilityList.1": `Als je "<bold>Je beveiligingssleutel succesvol geregistreerd</bold>" ziet, ben je klaar met deze stap. Bewaar je tweede beveiligingssleutel veilig. Zorg ervoor dat je het later als back-up registreert als je de zojuist geregistreerde versie kwijtraakt.`,
  "registerSecurityKey.expander.body.screenPossibilityList.2":
    "Als je wordt gevraagd een pincode in te voeren, ben je nog niet klaar. Ga verder met de volgende stappen:",
  "registerSecurityKey.expander.body.setUpNewPin.1": `Voer de <bold>pincode van uw beveiligingssleutel</bold> in de <bold>velden <bold>Pincode</bold> en Pincode bevestigen</bold> in en klik vervolgens op <bold>Volgende</bold>.`,
  "registerSecurityKey.expander.body.setUpNewPin.2":
    "Houd bij de volgende prompt uw beveiligingssleutel opnieuw ingedrukt. Het kan zijn dat je een pop-up krijgt met informatie over je beveiligingssleutel. Klik op <bold>Toestaan</bold>.",
  "registerSecurityKey.expander.body.setUpNewPin.3": `Als de registratie is gelukt, ziet u "<bold>U hebt uw beveiligingssleutel succesvol geregistreerd</bold>." Als je dit bericht niet ziet, probeer het registratieproces dan opnieuw in een andere browser.`,
  "registerSecurityKey.expander.body.setUpNewPin.3.1":
    "Als de registratie succesvol is, zie je <bold>”Je hebt je beveiligingssleutel succesvol geregistreerd. </bold>”",
  "registerSecurityKey.expander.body.setUpNewPin.3.2":
    "Als je dit bericht niet ziet, moet je de registratiestap opnieuw proberen. Sluit het Midway-venster aan de rechterkant en probeer deze stap opnieuw door op de knop ”Beveiligingssleutel op Midway registreren” te klikken om deze stap opnieuw te proberen.",
  "registerSecurityKey.expander.body.secondKeySafe":
    "Bewaar je tweede beveiligingssleutel veilig. Je kunt het later als back-up registreren als je de zojuist geregistreerde versie kwijtraakt.",
  "registerSecurityKey.expander.body.note":
    "<bold>Opmerking</bold>: Als er een fout optreedt met betrekking tot ”Device Compliance” of ”AEA”, moet u Easy Onboarding opnieuw starten door uit te loggen bij ”tokenadmin” en opnieuw in te loggen om de updates van het apparaat te kunnen voltooien. Volg de instructies in je persoonlijke e-mailadres om dit proces opnieuw te starten. Als dit de fout niet verhelpt, gebruik dan de link ”Contact opnemen met de klantenservice” voor hulp.",
  "registerSecurityKey.checkbox.confirmation":
    "Ik bevestig dat ik mijn beveiligingssleutel heb geregistreerd.",
  "registerSecurityKey.button.sendTempPin": "Tijdelijke pincode verzenden",
  "registerSecurityKey.button.sendTempPin.loadingText": "Verzenden...",
  "registerSecurityKey.button.sendTempPin.error":
    "Er is een fout opgetreden bij het verzenden van de pincode. Probeer het over een paar minuten opnieuw.",
  "registerSecurityKey.button.sendTempPin.errorContactSupport":
    "We konden je geen tijdelijke pincode sturen. Neem contact op met de klantenservice.",
  "registerSecurityKey.button.registerSecurityKey":
    "Registreer de beveiligingssleutel op Midway",
  "registerSecurityKey.link.resend": "Opnieuw verzenden.",
  "registerSecurityKey.image.securityKeyGrid.altText": `Een raster van zes afbeeldingen met typen beveiligingssleutels. De typen zijn "YubiKey 4", "YubiKey 4 Nano”, "USB-C YubiKey”, "USB-C Nano”, "Zukey” en "USB-C Zukey”.`,
  "registerSecurityKey.image.tempPinLogin.altText": `Een schermafbeelding van de aanmeldingspagina van Amazon Midway. De pagina zegt "Aanmelden”, bevat velden om een Amazon-gebruikersnaam en Amazon-pincode in te voeren, en heeft een knop met de tekst "Aanmelden”.`,
  "registerSecurityKey.image.midwayWelcomeMessage.altText": `Een screenshot van een voorbeeldpagina met een welkomstbericht. Op de voorbeeldwelkomstpagina staat "Welkom jeffbezos!. Ga naar het registratieportaal voor beveiligingssleutels om uw beveiligingssleutels te registreren of te beheren."`,
  "registerSecurityKey.image.securityKeyDashboard.altText": `Een screenshot van de welkomstpagina van het Amazon Enterprise Access-beveiligingssleuteldashboard. Op de pagina staat "Welkom bij het dashboard met beveiligingssleutels. Amazon Enterprise Access” en heeft een knop met de tekst "Beveiligingssleutel registreren”.`,
  "registerSecurityKey.image.securityKeyGetStarted.altText": `Een schermafbeelding van de pagina Aan de slag met je beveiligingssleutel. Op de pagina staat "Ga aan de slag met je beveiligingssleutel. Haal een beveiligingssleutel op bij het dichtsbijzijnde IT-ondersteuningskantoor of bij de IT-automaat. Nadat u een beveiligingssleutel hebt opgehaald, klikt u op Registratie starten. Belangrijk: U moet uw nieuwe beveiligingssleutel bij u hebben om deze te kunnen registreren. U moet nu uw nieuwe beveiligingssleutel invoeren. "en heeft een knop met de tekst "Registratie starten".`,
  "registerSecurityKey.image.securityKeyGetStartedGrid.altText": `Een schermafbeelding van de pagina Aan de slag met je beveiligingssleutel. Op de pagina staat "Ga aan de slag met je beveiligingssleutel. Welke beveiligingssleutel heb je opgehaald? Selecteer de beveiligingssleutel die overeenkomt met de sleutel die je hebt. "en heeft een raster van zes typen beveiligingssleutels. De vermelde typen beveiligingssleutels zijn "YubiKey 4", "YubiKey 4 Nano”, "USB-C YubiKey”, "USB-C Nano”, "Zukey” en "USB-C Zukey”.`,
  "registerSecurityKey.image.securityKeyGetStartedOtp.altText": `Een schermafbeelding van de pagina Aan de slag met je beveiligingssleutel. Op de pagina staat ”Ga aan de slag met je beveiligingssleutel. Steek eerst uw beveiligingssleutel in een USB-poort op uw computer. Houd vervolgens de beveiligingssleutel 3-5 seconden ingedrukt totdat er tekst wordt weergegeven in het onderstaande vak. ”en heeft een tekstinvoerveld met opvultekst met de tekst ”Uw OTP verschijnt hier, typ niet in dit vak”.`,
  "registerSecurityKey.image.pressingSecurityKeySide.altText": `Een geanimeerde gif-afbeelding van een persoon die op de zijkant van een beveiligingssleutel drukt die op een laptop is aangesloten`,
  "registerSecurityKey.image.pressingSecurityKeyTop.altText":
    "Een geanimeerde gif-afbeelding van een persoon die op de bovenkant van een beveiligingssleutel drukt die op een laptop is aangesloten",
  "registerSecurityKey.image.securityKeyCreatePin.altText": `Een schermafbeelding van de pagina voor het aanmaken van een pincode voor de beveiligingssleutel. Op de pagina staat U moet een nieuwe pincode voor de beveiligingssleutel instellen om deze beveiligingssleutel te registreren. Vergeet de pincode van uw beveiligingssleutel NIET. Je pincode wordt gebruikt om je aan te melden bij Amazon-bronnen en verbinding te maken met de VPN met je beveiligingssleutel. De pagina bevat ook een tekstinvoerveld met het label ”Nieuwe pincode (8-64 ASCII-tekens), een ander tekstinvoerveld met het label "Nieuwe pincode bevestigen” en een knop met de tekst "Doorgaan".`,
  "registerSecurityKey.image.securityKeySuccess.altText": `Een screenshot van een succesbericht. Het bericht zegt: ”Je hebt je beveiligingssleutel succesvol geregistreerd. Het kan tot 1 uur duren voordat deze beveiligingssleutel werkt op alle tools en bronnen van Amazon.`,
  "registerSecurityKey.image.securityKeyCreateNewPin.altText":
    "Een schermafdruk van een pop-upformulier om een nieuwe pincode in te stellen. In de pop-up staat ”Pincode vereist. Stel een nieuwe pincode in voor uw beveiligingssleutel”. De pop-up bevat ook een tekstinvoerveld met het label ”PIN” en een ander tekstinvoerveld met het label ”Pincode bevestigen”.",
  "connectVpn.expander.title": "Maak verbinding met het Amazon Network (VPN)",
  "connectVpn.expander.body.intro":
    "Je moet verbinding maken met VPN om toegang te krijgen tot interne bronnen terwijl je op locatie of op afstand werkt. Bij VPN-sessies wordt elke 18 uur een time-out uitgevoerd en moet je dan opnieuw verbinding maken.",
  "connectVpn.expander.body.mac.step1":
    "Open <bold>Cisco AnyConnectSecure</bold> Mobility Client.",
  "connectVpn.expander.body.mac.step2":
    "De Cisco AnyConnect-client verschijnt. <bold>Klik op Verbinden</bold>.",
  "connectVpn.expander.body.mac.step3":
    "Er verschijnt een pop-up. Selecteer in het veld <bold>Groep</bold> de optie <bold>Orca-Amazon-Onboarding</bold> in de keuzelijst.",
  "connectVpn.expander.body.mac.step4":
    "Voer je Amazon-gebruikersnaam in het veld <bold>Gebruikersnaam</bold> in.",
  "connectVpn.expander.body.mac.step5":
    "Voer in het veld Beveiligingssleutel PIN + Druk op de <bold>beveiligingssleutel de pincode</bold> in en houd vervolgens de beveiligingssleutel ingedrukt totdat er een lange reeks tekens in het veld verschijnt. Klik niet op OK - wanneer u uw beveiligingssleutel ingedrukt houdt, wordt de VPN-verbinding automatisch geverifieerd.",
  "connectVpn.expander.body.mac.step5.warning":
    "In het <bold>veld Beveiligingssleutel PIN + Druk op de beveiligingssleutel</bold> kan in plaats daarvan <bold>Wachtwoord</bold> staan. Voer hier niet je Amazon-wachtwoord in. Voer de pincode van uw beveiligingssleutel in en houd vervolgens uw beveiligingssleutel ingedrukt.",
  "connectVpn.expander.body.mac.step6":
    "Er verschijnt een pop-up waarin wordt bevestigd dat je via VPN verbonden bent met het Amazon-netwerk. Selecteer <bold>OK</bold>.",
  "connectVpn.image.mac.step2.altText":
    "Schermafdruk van de Cisco AnyConnect Secure Mobility Client-toepassing.",
  "connectVpn.image.mac.step3.altText":
    "Schermafdruk van een pop-up met de velden voor groepsnaam, gebruikersnaam en beveiligingssleutel om verbinding te maken met de VPN met de Cisco AnyConnect Secure Mobility Client-toepassing waarbij ORCAAmazon-onboarding is geselecteerd in de keuzelijst in het veld Groep.",
  "connectVpn.image.mac.step4.altText":
    "Schermafdruk van een pop-up met de velden voor groeps-, gebruikersnaam- en beveiligingssleutels om verbinding te maken met de VPN met de Cisco AnyConnect Secure Mobility Client-toepassing met een rood vak dat het veld Gebruikersnaam markeert.",
  "connectVpn.image.mac.step5.altText":
    "Schermafdruk van een pop-up met de velden Groeps-, Gebruikersnaam- en Beveiligingssleutel om verbinding te maken met de VPN met de Cisco AnyConnect Secure Mobility Client-toepassing met een rood vak waarin het veld Beveiligingssleutel PIN + Druk op de beveiligingssleutel wordt gemarkeerd en de OK-knop is doorgestreept met een rode X.",
  "connectVpn.image.mac.step6.altText":
    "Schermafdruk van een succesvolle verbinding met de VPN.",
  "connectVpn.expander.body.windows.step1":
    "Druk op de <bold>Windows-toets</bold> op deze laptop die toegang geeft tot het ”Startmenu”.",
  "connectVpn.expander.body.windows.step2":
    "Selecteer <bold>Cisco AnyConnect</bold> in het menu Start (dit is de software van Amazon voor toegang tot VPN).",
  "connectVpn.expander.body.windows.step3":
    "De Cisco AnyConnect-client verschijnt. De Cisco AnyConnect-software wordt gebruikt voor Amazon's VPN. Klik op <bold>Verbinden</bold>.",
  "connectVpn.expander.body.windows.step4":
    "Klik om het keuzemenu in het veld Groep te openen. Selecteer <bold>Orca-Amazon-onboarding</bold>.",
  "connectVpn.expander.body.windows.step5":
    "Voer je Amazon-gebruikersnaam in het veld <bold>Gebruikersnaam</bold> in.",
  "connectVpn.expander.body.windows.step6":
    "Voer in het veld <bold>Wachtwoord</bold> de pincode van uw beveiligingssleutel in en houd vervolgens uw beveiligingssleutel ingedrukt totdat er een lange reeks tekens in het veld verschijnt. Klik niet op <bold>OK</bold>. Als u uw beveiligingssleutel ingedrukt houdt, wordt de VPN-verbinding automatisch geverifieerd.",
  "connectVpn.expander.body.windows.step7":
    "Wanneer de VPN is verbonden, verschijnt er een beveiligingsmelding die de verbinding bevestigt. Klik op <bold>OK</bold> om het dialoogvenster te sluiten.",
  "connectVpn.image.windows.step1.altText": "Druk op de Windows-toets.",
  "connectVpn.image.windows.step2.altText":
    "Selecteer Cisco AnyConnect in het startmenu.",
  "connectVpn.image.windows.step4.altText":
    "Selecteer Orca-Amazon-Onboarding in de keuzelijst in het veld Groep.",
  "connectVpn.image.windows.step6.altText":
    "Voer de pincode van uw beveiligingssleutel in en houd vervolgens uw beveiliging ingedrukt.",
  "connectVpn.button.tooltip.vpnNotConnectedHint":
    "Maak verbinding met de VPN.",
  "amazonPassword.expander.title": "Amazon-wachtwoord aanmaken",
  "amazonPassword.expander.body.intro1":
    "Vervolgens maak je het wachtwoord dat je elke dag gebruikt om in te loggen op je laptop en toegang te krijgen tot Amazon-tools.",
  "amazonPassword.expander.body.intro2":
    "U voert de pincode van uw beveiligingssleutel in en houdt vervolgens uw vinger tegen uw USB-beveiligingssleutel om uw wachtwoord voor de eerste keer in te stellen. <bold>Onthoud dit wachtwoord</bold>, want je hebt het nodig om door te gaan met het instellen van je laptop.",
  "amazonPassword.expander.body.banner":
    "Wanneer u uw wachtwoord aanmaakt, wordt u gevraagd uw vinger tegen uw USB-beveiligingssleutel te drukken. Dit zal voor u op ”enter” drukken. U hoeft niet op ”enter” of ”submit” te klikken. Als u een foutmelding krijgt, negeer deze dan en ga verder met de installatie van uw laptop.",
  "amazonPassword.expander.body.confirmation":
    "Ik bevestig dat ik met succes mijn wachtwoord heb aangemaakt.",
  "amazonPassword.button.createPassword": "Amazon-wachtwoord aanmaken",
  "preCacheADMobile.expander.title": "Pre-Cache AD Mobile installeren",
  "preCacheADMobile.expander.body.intro":
    "Bij Amazon gebruiken we een tool genaamd Precache AD om je accountgegevens op het Amazon-netwerk te synchroniseren met de laptop die je gebruikt. Dit zorgt ervoor dat je elke dag kunt inloggen op je Amazon-laptop met je Amazon-wachtwoord en -gebruikersnaam.",
  "preCacheADMobile.expander.body.step1":
    "Open de <bold>Self Service</bold> {logo} applicatie op je desktop.",
  "preCacheADMobile.expander.body.step2": "Vul je Amazon-gebruikersnaam in.",
  "preCacheADMobile.expander.body.step3":
    "Voer de pincode van uw beveiligingssleutel in en druk op uw beveiligingssleutel.",
  "preCacheADMobile.expander.body.step4": `Voer in het zoekvak linksboven "<bold>Pre-Cache</bold>” in.`,
  "preCacheADMobile.expander.body.step5":
    "Wanneer de zoekresultaten worden geladen, klikt u op <bold>Cache</bold>.",
  "preCacheADMobile.expander.body.step6":
    "Vul je Amazon-gebruikersnaam in. Klik op <bold>OK</bold>.",
  "preCacheADMobile.expander.body.step7":
    "Voer je Amazon-wachtwoord in. Klik op <bold>OK</bold>.",
  "preCacheADMobile.expander.body.step8":
    "Het script wordt gedurende 2-3 minuten geruisloos uitgevoerd. Als je klaar bent, een ”Succes! ”Er verschijnt een pop-up. Klik op <bold>OK</bold>.",
  "preCacheADMobile.image.step3.altText":
    "Schermafdruk van de weergave om in te loggen met je Amazon-gebruikersnaam en pincode voor de beveiligingssleutel.",
  "preCacheADMobile.image.step4.altText":
    "Schermafdruk van de weergave voor het zoeken naar Pre-Cache.",
  "preCacheADMobile.image.step6.altText":
    "Schermafdruk van de weergave voor het invoeren van je Amazon-gebruikersnaam.",
  "preCacheADMobile.image.step7.altText":
    "Schermafdruk van de weergave voor het invoeren van je Amazon-wachtwoord.",
  "preCacheADMobile.image.step8.altText":
    "Schermafdruk van de weergave van de Pre-Cache AD Mobile-app die met succes is geïnstalleerd.",
  "preCacheADMobile.logo.altText":
    "Logo van de AD-app die vóór de cache kan worden geïnstalleerd via zelfbediening",
  "banner.openViaWelcome":
    "Open deze pagina door op de welkomstpagina op ”Aan de slag” te klikken.",
  "allStepsCompleted.title": "👏 Geweldig gedaan!",
  "allStepsCompleted.mac.nextTo": "Vervolgens moet je:",
  "allStepsCompleted.win.nextTo":
    "Vervolgens moet je voor de eerste keer inloggen:",
  "allStepsCompleted.win.step1": "Stap 1: Maak verbinding met VPN",
  "allStepsCompleted.win.instructionSent":
    "Deze instructies zijn ook naar u gemaild",
  "allStepsCompleted.mac.postLoginStep1": "Meld je af bij dit account.",
  "allStepsCompleted.mac.postLoginStep2":
    "Controleer je persoonlijke e-mailadres voor instructies om in te loggen op je Amazon-account.",
  "allStepsCompleted.win.step1.subStep1":
    "Start deze laptop opnieuw op door op de Windows-toets te drukken en <bold>Opnieuw opstarten</bold> te selecteren in het menu <bold>Start</bold>.",
  "allStepsCompleted.win.step1.subStep2":
    "Klik op het aanmeldingspictogram op het netwerk om verbinding te maken met Amazon VPN. Dit pictogram ziet eruit als twee computerschermen.",
  "allStepsCompleted.win.step1.subStep2.note":
    "<bold>Opmerking:</bold> Verbinding maken met VPN vanaf het inlogscherm is alleen nodig voor de eerste keer dat je een account instelt. Je hoeft na de eerste keer geen VPN te gebruiken om in te loggen. ",
  "allStepsCompleted.windows.vpnIcon.altText":
    "Een pictogram met twee computerschermen die geel zijn gemarkeerd.",
  "allStepsCompleted.win.step1.subStep3":
    "De Cisco AnyConnect-client verschijnt. Klik op <bold>Verbinden</bold>.",
  "allStepsCompleted.win.step1.subStep4":
    "Klik om het keuzemenu in het veld <bold>Groep</bold> te openen. Selecteer <bold>Pre-Login</bold>.",
  "allStepsCompleted.win.step1.preLogin.altText":
    "Een afbeelding van de Cisco VPN-client waarbij de Pre-Login-groep is geselecteerd.",
  "allStepsCompleted.win.step1.subStep5":
    "Voer je Amazon-gebruikersnaam in het veld <bold>Gebruikersnaam</bold> in (je gebruikersnaam is {username}).",
  "allStepsCompleted.win.step1.subStep6":
    "Voer in het veld <bold>Wachtwoord</bold> de pincode van uw beveiligingssleutel in en houd vervolgens uw beveiligingssleutel ingedrukt totdat er een lange reeks tekens in het veld verschijnt. Klik niet op <bold>OK</bold> wanneer u uw beveiligingssleutel ingedrukt houdt, de VPN-verbinding wordt automatisch geverifieerd. ",
  "allStepsCompleted.win.step1.securityKey.altText":
    "`Een foto van een vinger die de beveiligingssleutel aanraakt die op een computer is aangesloten.",
  "allStepsCompleted.win.step1.subStep6.note":
    "<bold>Belangrijk:</bold> Als je verbinding maakt met VPN, gebruik dan niet je Amazon-wachtwoord, zelfs niet als er ”Wachtwoord” staat. Gebruik de pincode van uw beveiligingssleutel en oefen vervolgens gedurende 3-5 seconden lichte druk uit op de beveiligingssleutel die op uw computer is aangesloten.",
  "allStepsCompleted.win.step1.subStep7":
    "Wanneer de VPN is verbonden, verschijnt er een beveiligingsmelding. Klik op <bold>OK</bold> om het dialoogvenster te sluiten. Je wordt teruggeleid naar het inlogscherm van Windows. ",
  "allStepsCompleted.win.step2": "Stap 2: Meld je aan bij je Amazon-account",
  "allStepsCompleted.win.step2.intro":
    "Log in op je Amazon-account met je gebruikersnaam en het wachtwoord dat je hebt aangemaakt.",
  "allStepsCompleted.win.step2.subStep1":
    "Typ <bold>ANT\\{username} in het veld Gebruikersnaam</bold>. Zorg ervoor dat je de ”ANT\\” vóór je gebruikersnaam plaatst.",
  "allStepsCompleted.win.step2.subStep2":
    "Typ in het veld Wachtwoord je <bold>Amazon-wachtwoord</bold> en druk op Enter.",
  "allStepsCompleted.win.step2.subStep2.note1":
    "<bold>Belangrijk:</bold> Nadat je bent ingelogd op je Amazon-account, moet je de verbinding met <bold>VPN verbreken</bold> om toegang te krijgen tot Amazon-bronnen. Als u de verbinding niet verbreekt, hebt u geen toegang tot bronnen. U kunt Cisco 'AnyConnect in het Startmenu vinden om de verbinding met de VPN te verbreken.",
  "allStepsCompleted.win.step2.subStep2.note2":
    "Als VPN nodig is voor andere diensten die specifiek zijn voor uw functie, moet u <bold>Orca-Corp-VPN gebruiken. </bold>",
  "allStepsCompleted.win.step3":
    "Stap 3: Open de installatiehandleiding voor de laptop",
  "allStepsCompleted.win.step3.intro":
    "Nadat je bent ingelogd, ga je naar {newHirePortalLink} om het instellen van je laptop voor veelgebruikte tools die binnen Amazon worden gebruikt, zoals Chime, Slack en Outlook, te voltooien. ",
  failedToRender:
    "Er is iets misgegaan. Ga naar {link} en neem contact op met de IT-ondersteuning voor verdere hulp.",
  "error.serviceNotAvailable.text1":
    "De Amazon Day 1-app is momenteel niet beschikbaar.",
  "error.serviceNotAvailable.text2":
    "Als je een nieuwe medewerker bent, ga dan naar de IT-installatiehandleiding op firstaid.amazon-corp.com/vnho vanaf je Amazon-apparaat of je persoonlijke apparaat met internetverbinding om de laptopconfiguratie te voltooien. U hebt uw wervingsmanager of IT-ondersteuning nodig om u te helpen bij het installatieproces. Als uw personeelsmanager niet beschikbaar is, gebruik dan de link 'Ondersteuning krijgen' op de pagina met instructies voor eerste hulp.",
  "error.somethingWentWrong":
    "Er is iets misgegaan. Probeer het opnieuw of {chatWithITSupportLink} voor verdere hulp.",
  "error.accountIssues":
    "Er is een probleem met je account. Alsjeblieft {chatWithITSupportLink}.",
  "error.ineligibleError.text1":
    "Er is een probleem met je account. Lees hieronder voordat je contact opneemt met de klantenservice.",
  "error.ineligibleError.text2":
    "Als je een nieuwe medewerker bent, ga dan vanaf je persoonlijke apparaat met internetverbinding naar de IT-installatiehandleiding op firstaid.amazon-corp.com/vnho om de installatie van je laptop te voltooien.",
  "error.ineligibleError.text3":
    "Als je een Amazoniaan bent die je vervangende laptop installeert, volg dan de instructies die bij de laptop zijn geleverd.",
  "error.ineligibleError.text4":
    "Als je nog steeds hulp nodig hebt, ga dan naar firstaid.amazon-corp.com voor ondersteuning.",
  "error.notVerifiableError.text1":
    "Het spijt ons, identiteitsverificatie is momenteel niet beschikbaar.",
  "error.notVerifiableError.text2":
    "Om uw identiteit te verifiëren, gelieve {chatWithITSupportLink} te gebruiken. Een van onze agenten helpt u graag bij het verificatieproces. We verontschuldigen ons voor het ongemak en stellen uw begrip op prijs.",
  "error.beforeStartDate":
    "Je startdatum en eerste dag is {startDate}. Sluit je laptop tot die tijd. Heb je hulp nodig? {chatWithITSupportLink}.",
  "itHelpMsg.day1BlockingIssue":
    "Ik heb problemen met het starten van het Easy Onboarding-proces voor nieuwe medewerkers en heb hulp nodig bij het installeren van mijn laptop op de eerste dag.",
  "itHelpMsg.contactSupportOnboardThroughChime":
    "Ik heb hulp nodig bij het verifiëren van mijn identiteit voor de onboarding van nieuwe medewerkers via een Chime-videogesprek.",
  "itHelpMsg.verificationIssueAndAssist":
    "Ik heb problemen met het verifiëren van mijn identiteit via het Easy Onboarding-proces voor nieuwe medewerkers en heb hulp nodig bij de installatie van mijn laptop op de eerste dag.",
  "itHelpMsg.verificationIssue":
    "Ik heb problemen met het instellen van mijn laptop als onderdeel van het onboardingproces voor nieuwe medewerkers en heb hulp nodig bij het verifiëren van mijn identiteit.",
  "itHelpMsg.verificationAssist":
    "Ik heb hulp nodig bij het verifiëren van mijn identiteit als onderdeel van het Easy Onboarding-proces voor nieuwe medewerkers en heb hulp nodig bij de onboarding.",
  "itHelpMsg.settingUpLaptop":
    "Ik heb problemen met het instellen van mijn laptop als onderdeel van het onboardingproces voor nieuwe medewerkers.",
  "itHelpMsg.usernameIssue":
    "Ik heb problemen met mijn gebruikersnaam als onderdeel van mijn onboardingproces voor nieuwe medewerkers.",
  "itHelpMsg.midwayAssist":
    "Ik heb hulp nodig bij Midway en het registratieproces voor beveiligingssleutels als onderdeel van de onboarding van mijn nieuwe medewerkers.",
  "itHelpMsg.midwayIssue":
    "Ik heb problemen met het registratieproces voor Midway-sleutels als onderdeel van de onboarding van mijn nieuwe medewerkers. Ik heb al gevraagd om een tijdelijke pincode te sturen.",
  "itHelpMsg.vpnIssue":
    "Ik heb een probleem met de VPN-verbinding als onderdeel van mijn onboarding voor nieuwe medewerkers.",
  "itHelpMsg.passwordIssue":
    "Ik heb een probleem met de tool voor het opnieuw instellen van wachtwoorden als onderdeel van mijn onboarding voor nieuwe medewerkers.",
  "itHelpMsg.preCacheADIssue":
    "Ik heb een probleem met de Pre-Cache AD-stap bij de onboarding van mijn nieuwe medewerker voor mijn Mac-apparaat.",
  "mediacheck.failure.permission":
    "Om toegang toe te staan, klikt u op het camerapictogram {icon} in de adresbalk van uw browser. Mogelijk moet u de pagina opnieuw laden om wijzigingen toe te passen.",
  "mediacheck.failure.nodevice":
    "Er zijn geen camera-apparaten gevonden. Zorg ervoor dat een camera is aangesloten en werkt en laad de pagina opnieuw.",
  "mediacheck.failure.other":
    "Door een probleem kon de pagina niet detecteren of je een bruikbare camera hebt geïnstalleerd.",
  "mediacheck.failure.title": "De camera is geblokkeerd.",
  "mediacheck.failure.cameraalt": "Pictogram van de camera",
  "cameraOn.text": "Begin met opnemen en vervolgens {text}",
  "getSupport.header": "Krijg ondersteuning",
  "getSupport.footer": "Nog steeds ondersteuning nodig?",
  "getSupport.chatSupport.clickToStart": "Chat starten",
  "getSupport.chatSupport.clickToStartNew": "Een nieuwe chat starten",
  "getSupport.faq.password.header":
    "Veelgestelde vragen over Amazon-wachtwoorden",
  "getSupport.faq.password.section1.title": "Wanneer verloopt mijn wachtwoord?",
  "getSupport.faq.password.section1.contents": "",
  "getSupport.faq.vpn.header": "VEELGESTELDE VRAGEN OVER VPN",
  "getSupport.faq.vpn.section1.title": "Cisco CSD-configuratiefout",
  "getSupport.faq.vpn.section1.contents": "Tijdelijke aanduiding",
  "getSupport.faq.general.header": "Algemene veelgestelde vragen",
  "getSupport.faq.general.section1.title":
    "Wat moet ik doen als ik geen verificatiecode heb ontvangen?",
  "getSupport.faq.general.section1.contents": "verificatiecode",
  "getSupport.faq.midway.header": "Veelgestelde vragen over Midway",
  "getSupport.faq.midway.section1.title": "Wat is een beveiligingssleutel?",
  "getSupport.faq.midway.section1.contents":
    "Een beveiligingssleutel is een fysieke USB-stick die in uw computer wordt geplaatst. We hebben je een pakket gestuurd met twee USB-beveiligingssleutels. Bewaar de tweede reservesleutel op een veilige plaats, voor het geval uw primaire sleutel verloren of beschadigd is.",
  "getSupport.faq.username.header":
    "Veelgestelde vragen over Amazon-gebruikersnaam",
  "getSupport.faq.username.section1.title": "Wat is een Amazon-gebruikersnaam?",
  "getSupport.faq.username.section1.contents":
    "Bij Amazon gebruik je je gebruikersnaam om in te loggen op je computer en toegang te krijgen tot tools en bronnen van Amazon. Je gebruikersnaam is het deel van je Amazon-e-mailadres dat vóór de @ staat.",
  "getSupport.idv.title":
    "Chat met de IT-ondersteuning om je identiteit te verifiëren",
  "getSupport.idv.body.content1":
    "Als u problemen ondervindt met het bovenstaande proces of andere problemen hebt, neem dan contact op met het IT-ondersteuningsteam.",
  "getSupport.idv.body.content2":
    "Het IT-ondersteuningsteam kan u helpen bij het verifiëren van uw identiteit via een videogesprek.",
  "arialabels.clickToCloseChat":
    "Klik om het ondersteuningspaneel voor chats te sluiten",
  "arialabels.clickToStartChat": "Klik om de chatondersteuning te starten",
  "arialabels.clickToOpenChat":
    "Klik om het ondersteuningspaneel voor chats te openen",
  "arialabels.clickToCloseGetSupport":
    "Klik om het paneel voor het verkrijgen van ondersteuning te sluiten",
  "arialabels.clickToOpenGetSupport":
    "Klik om het paneel voor het verkrijgen van ondersteuning te openen",
};

export default dutch;
