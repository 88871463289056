import { useContext, useMemo } from "react";

import { ConsentVersion } from "@/helpers/consent/consent";
import { getConsent } from "@/helpers/consent/consentData";

import { AppContext } from "../stores";
import { useHiringRegion } from "./useRegion";

export interface I18NConsent {
  "ivv.consent.version": string;
  "ivv.consent.header": string;
  "ivv.consent.body": string;
  "ivv.consent.disclaimer": string;
  "ivv.consent.check.consent": string;
  "ivv.consent.check.location": string;
  "ivv.consent.button.agree": string;
  "ivv.consent.button.back": string;
}

/**
 * Converts a ConsentVersion instance to a Record that can be consumed by
 * the formatJS library.
 * @param consent The consent version instance
 * @returns
 */
export const consentToI18n = (consent: ConsentVersion): I18NConsent => ({
  "ivv.consent.version": consent.Version,
  "ivv.consent.header": consent.Header,
  "ivv.consent.body": consent.LegalBody,
  "ivv.consent.disclaimer": consent.Disclaimer,
  "ivv.consent.check.consent": consent.ConsentCheck,
  "ivv.consent.check.location": consent.LocationCheck,
  "ivv.consent.button.agree": consent.ConsentButton,
  "ivv.consent.button.back": consent.BackButton,
});

/**
 * This hook reads the hiring country and required document counts
 * from the application state and returns the corresponding IVV consent
 * language which should be shown to the user.
 *
 * This needs to be consumed by the IntlProvider to allow the content to
 * be rendered by the FormatJS library components.
 *
 * @returns
 */
export const useConsentLanguage = () => {
  const { state } = useContext(AppContext);
  const hiringRegion = useHiringRegion();
  return useMemo<I18NConsent>(() => {
    const consent = getConsent(
      hiringRegion,
      state.requiredDocsForHiringCountry
    );
    return consentToI18n(consent);
  }, [hiringRegion, state.requiredDocsForHiringCountry]);
};
