import { IconGlobe } from "@amzn/stencil-react-components/icons";
import {
  PageHeaderDropdown,
  PageHeaderDropdownButton,
} from "@amzn/stencil-react-components/page";
import { trackStructEvent } from "@snowplow/browser-tracker";
import React, { FC, useContext, useState } from "react";

import { useSelectedLanguage } from "@/hooks/useSelectedLanguage";
import { AppContext } from "@/stores/appStore";
import { ActionType } from "@/stores/constants";
import { Day1EventAction, Day1EventCategory } from "@/types/snowplow-events";

import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from "../../i18n/locales";

// Setting this to false to hide the LanguageSelector component until we are ready to launch EZO-localization
// set this to true to enable local development

interface LanguageSelectorProps {
  showLanguageSelector?: boolean;
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({
  showLanguageSelector = false,
}) => {
  const initialLanguage = useSelectedLanguage();
  const { dispatch } = useContext(AppContext);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    initialLanguage ? initialLanguage : DEFAULT_LANGUAGE.locale
  );
  const handleLangueSelect = (locale: string) => {
    trackStructEvent({
      category: Day1EventCategory.Header,
      action: Day1EventAction.SelectLanguage,
      label: locale,
    });
    setSelectedLanguage(locale);
    dispatch({
      type: ActionType.SET_SELECTED_LANGUAGE,
      selectedLanguage: locale,
    });
  };

  const items = SUPPORTED_LANGUAGES.map((language) => (
    <PageHeaderDropdownButton
      dataTestId={`languageSelector-${language.locale}`}
      key={language.locale}
      onClick={() => {
        handleLangueSelect(language.locale);
      }}
    >
      {language.label}
    </PageHeaderDropdownButton>
  ));

  return (
    <>
      {showLanguageSelector && ( // this is to be removed once EZO localization development is complete.
        <PageHeaderDropdown
          id="languageSelectorLink"
          dataTestId={"languageSelectorLink"}
          icon={<IconGlobe aria-hidden={true} />}
          items={items}
          paddingHorizontal={0}
        >
          {selectedLanguage.substring(0, 2)}
        </PageHeaderDropdown>
      )}
    </>
  );
};

export default LanguageSelector;
