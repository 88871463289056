import { Button } from "@amzn/stencil-react-components/button";
import {
  IconClock,
  IconPerson,
  IconSize,
} from "@amzn/stencil-react-components/icons";
import { Col, Row, View } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

type VerifyWithItFlyoutContentProps = {
  /**
   * The handler to initialize the IT chat support session.
   */
  chatInitializedHandler: () => void;
};

/**
 * The Flyout content to inform users about performing a manual IDV with ITSE
 * via Chime.
 */
export default function VerifyWithItFlyoutContent({
  chatInitializedHandler,
}: VerifyWithItFlyoutContentProps): JSX.Element {
  const intl = useIntl();
  return (
    <View padding="S400">
      <Col gridGap="S500">
        <Text fontSize="T400" fontWeight="medium">
          <FormattedMessage id="getSupport.idv.title" />
        </Text>
        <Text>
          <FormattedMessage id="getSupport.idv.body.content1" />
        </Text>
        <Text>
          <FormattedMessage id="getSupport.idv.body.content2" />
        </Text>
        <Col gridGap="S200">
          <Row gridGap="S200" alignItems="center">
            <IconClock
              aria-hidden={true}
              size={IconSize.Small}
              color="neutral100"
            />
            <Text fontSize="T100" color="neutral90">
              <FormattedMessage
                id="identityCheck.pathchooser.card.estCompletionManual"
                values={{
                  b: (chunks: unknown) => <b>{chunks}</b>,
                  count: "30-40",
                  unit: intl.formatMessage({
                    id: "identityCheck.pathchooser.card.unitMinutes",
                  }),
                }}
              />
            </Text>
          </Row>
          <Row gridGap="S200" alignItems="center">
            <IconPerson
              aria-hidden={true}
              size={IconSize.Small}
              color="neutral100"
            />
            <Text fontSize="T100" color="neutral90">
              <FormattedMessage
                id="identityCheck.pathchooser.additionalConcerns.chimeVerificationType"
                values={{ b: (chunks: unknown) => <b>{chunks}</b> }}
              />
            </Text>
          </Row>
        </Col>
        <View width="30%">
          <Button onClick={chatInitializedHandler}>
            <Text fontSize="T200">
              <FormattedMessage id="text.chatWithITSupport" />
            </Text>
          </Button>
        </View>
      </Col>
    </View>
  );
}
