/* istanbul ignore file */ // Ignore this file since it has no side effects in testing environments.
import KatalLogger, { Level, LoggerConfig } from "@amzn/katal-logger";
import axios from "axios";
import { v4 as uuid } from "uuid";

import { getStageConfig, STAGE } from "@/helpers/stageConfig";

/**
 * CloudWatch metrics namespace used to store custom metrics emitted from the front end
 */
const EZO_FRONT_END_METRICS_NAMESPACE = "EZOFrontEndMetrics";

/**
 * The key of the CloudWatch metric dimension. Put another way, this is the label or name
 * of the metric dimension.
 */
const EZO_WEBSITE_METRIC_DIMENSION_KEY = "Website";

/**
 * CloudWatch metric dimension value for {@link EZO_WEBSITE_METRIC_DIMENSION_KEY} to specify which
 * application is publishing the metric. This package is the DayOneWizard (D1W), so the value is
 * `"DayOneWizard"`.
 */
const EZO_WEBSITE_METRIC_DIMENSION_VALUE = "DayOneWizard";

/**
 * An embedded metric containing information that the logger client requires to publish
 * to CloudWatch via AWS embedded metrics format (AWS EMF)
 */
export class EmbeddedMetric {
  /**
   * The name of the metric
   */
  public readonly metricName: string;

  /**
   * The metric unit
   */
  public readonly unit: string;

  /**
   * The value of the metric
   */
  public readonly value: number;

  /**
   * Optional additional dimensions. The publish metric methods will include the dimension
   * `Website` in the embedded metric. If adding additional dimensions, then you must
   * include a value for the dimension in the `context`
   */
  public readonly dimensions: Record<string, unknown>;

  constructor(
    /**
     * The name of the metric
     */
    metricName: string,
    /**
     * The metric unit
     */
    unit: string,
    /**
     * The value of the metric
     */
    value: number,
    /**
     * Optional additional dimensions. The publish metric methods will include the dimension
     * `Website` in the embedded metric. If adding additional dimensions, then you must
     * include a value for the dimension in the `context`
     */
    dimensions?: Record<string, unknown>
  ) {
    this.metricName = metricName;
    this.unit = unit;
    this.value = value;
    this.dimensions = dimensions ?? {};
  }

  /**
   * Make a metric payload that conforms to the AWS EMF specification
   * @returns a JS object that conforms to the AWS EMF specification
   * @see [AWS docs - _Specification: Embedded metric format_](https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch_Embedded_Metric_Format_Specification.html)
   */
  toEmfPayload() {
    this.dimensions[EZO_WEBSITE_METRIC_DIMENSION_KEY] =
      EZO_WEBSITE_METRIC_DIMENSION_VALUE;
    const dimensionKeys = Object.keys(this.dimensions);

    // Make the AWS EMF payload
    const emfPayload = {
      _aws: {
        Timestamp: Date.now(),
        CloudWatchMetrics: [
          {
            Namespace: EZO_FRONT_END_METRICS_NAMESPACE,
            Dimensions: [dimensionKeys],
            Metrics: [
              {
                Name: this.metricName,
                Unit: this.unit,
              },
            ],
          },
        ],
      },
      [this.metricName]: this.value,
      ...this.dimensions,
      [EZO_WEBSITE_METRIC_DIMENSION_KEY]: EZO_WEBSITE_METRIC_DIMENSION_VALUE,
    };
    return emfPayload;
  }
}

/**
 * Katal Logger instance class. To get a logger do Logger.instance...
 * For more inspiration, check out Omnia's logger implementation at https://code.amazon.com/packages/OmniaWebApp/blobs/ab581e5a3cfd543581aeb40c3bd9907fb2b07660/--/src/lib/logger.ts
 * Usage: Logger.instance.<level>(...)
 */
export class Logger extends KatalLogger {
  private static logger: Logger | undefined;
  private static sessionId: string;

  constructor(options?: LoggerConfig) {
    super({ ...Logger.getLoggerConfig(), ...options });
  }

  /**
   * Gets an instance of Logger. Use methods Logger.fatal instead of Logger.instance.fatal because Logger.fatal will include additional context.
   */
  private static get instance(): Logger {
    if (!this.logger) {
      this.logger = new Logger();
    }
    return this.logger;
  }

  /**
   * Either initializes a session ID or returns the initialized value in order to group messages to the same session.
   * This is helpful for before you have a real user to set via setUserSessionId.
   */
  public static configureSessionId(): string {
    if (!this.sessionId) {
      this.sessionId = uuid();
    }
    return this.sessionId;
  }

  /**
   * Set the current user session id.
   * @param userSessionId User session id.
   */
  public static setUserSessionId(userSessionId: string): void {
    this.sessionId = userSessionId;
  }

  /**
   * Returns the current session ID.
   */
  public static getSessionId(): string {
    return this.sessionId;
  }

  /**
   * This method configures a logger with an empty url endpoint so nothing will be logged to real endpoints.
   * Call this method in the test setup file.
   */
  public static configureTesting(): void {
    this.logger = new Logger({
      url: "",
      logToConsole: true,
      logThreshold: Level.DEBUG,
    });
  }

  private static getLoggerConfig(): LoggerConfig {
    return {
      url: getStageConfig().katalLoggerEndpoint,
      decodeStackTrace: true,
      logToConsole: STAGE !== "PROD",
      logThreshold: Level.INFO,
      recordMetrics: false, // Telemetry metrics
    };
  }

  /**
   * Retrieve the user system info from the localStorage if there is any. The
   * system info should be emitted by the main process in the Electron app.
   * @private
   */
  private static getSystemInfo() {
    const systemInfoStr = localStorage.getItem("systemInfo");
    if (systemInfoStr) {
      return JSON.parse(systemInfoStr) as Record<string, unknown>;
    } else {
      return undefined;
    }
  }

  /**
   * Add standard context to input.
   * @param context Existing context, optional.
   * @private
   */
  private static getEnhancedContext(context?: Record<string, unknown>) {
    return {
      // Used to group logs for a session.
      sessionIdentifier: Logger.configureSessionId(),

      // Used to identify the source of the log.
      appIdentifier: "Day1WizardApp",

      deviceInfo: Logger.getSystemInfo() ?? {},

      // Keep context passed in by the call to Logger.method(value, error, ctx)
      ...context,
    };
  }

  /**
   * Logs a fatal level error with context.
   * @param message Message to log.
   * @param error Error to log. Optional.
   * @param context Additional context to add.
   */
  public static fatal(
    message: string,
    error?: Error,
    context?: Record<string, unknown>
  ): Promise<void> {
    return error
      ? Logger.instance.fatal(
          message,
          error,
          Logger.getEnhancedContext(context)
        )
      : Logger.instance.fatal(message, Logger.getEnhancedContext(context));
  }

  /**
   * Logs a error level error with context.
   * @param message Message to log.
   * @param error Error to log. Optional.
   * @param context Additional context to add.
   */
  public static error(
    message: string,
    error?: Error,
    context?: Record<string, unknown>
  ): Promise<void> {
    return error
      ? Logger.instance.error(
          message,
          error,
          Logger.getEnhancedContext(context)
        )
      : Logger.instance.error(message, Logger.getEnhancedContext(context));
  }

  /**
   * Logs a warn level error with context.
   * @param message Message to log.
   * @param error Error to log. Optional.
   * @param context Additional context to add.
   */
  public static warn(
    message: string,
    error?: Error,
    context?: Record<string, unknown>
  ): Promise<void> {
    return error
      ? Logger.instance.warn(message, error, Logger.getEnhancedContext(context))
      : Logger.instance.warn(message, Logger.getEnhancedContext(context));
  }

  /**
   * Logs a info with context.
   * @param message Message to log.
   * @param error Error to log. Optional.
   * @param context Additional context to add.
   */
  public static info(
    message: string,
    error?: Error,
    context?: Record<string, unknown>
  ): Promise<void> {
    return error
      ? Logger.instance.info(message, error, Logger.getEnhancedContext(context))
      : Logger.instance.info(message, Logger.getEnhancedContext(context));
  }

  /**
   * Logs a debug with context.
   * @param message Message to log.
   * @param error Error to log. Optional.
   * @param context Additional context to add.
   */
  public static debug(
    message: string,
    error?: Error,
    context?: Record<string, unknown>
  ): Promise<void> {
    return error
      ? // eslint-disable-next-line testing-library/no-debugging-utils
        Logger.instance.debug(
          message,
          error,
          Logger.getEnhancedContext(context)
        )
      : // eslint-disable-next-line testing-library/no-debugging-utils
        Logger.instance.debug(message, Logger.getEnhancedContext(context));
  }

  public static warnErrorLike(
    message: string,
    errorLike: unknown,
    context?: Record<string, unknown>
  ): void {
    if (errorLike instanceof Error || axios.isAxiosError(errorLike)) {
      void Logger.warn(message, errorLike, context);
    } else {
      const errorLikeMessage = JSON.stringify(errorLike);
      void Logger.warn(
        `${message}
        ${errorLikeMessage}`,
        undefined,
        context
      );
    }
  }

  public static errorErrorLike(
    message: string,
    errorLike: unknown,
    context?: Record<string, unknown>
  ): void {
    if (errorLike instanceof Error || axios.isAxiosError(errorLike)) {
      void Logger.error(message, errorLike, context);
    } else {
      const errorLikeMessage = JSON.stringify(errorLike);
      void Logger.error(
        `${message}
        ${errorLikeMessage}`,
        undefined,
        context
      );
    }
  }

  public static fatalErrorLike(
    message: string,
    errorLike: unknown,
    context?: Record<string, unknown>
  ): void {
    if (errorLike instanceof Error || axios.isAxiosError(errorLike)) {
      void Logger.fatal(message, errorLike, context);
    } else {
      const errorLikeMessage = JSON.stringify(errorLike);
      void Logger.fatal(
        `${message}
        ${errorLikeMessage}`,
        undefined,
        context
      );
    }
  }

  /**
   * Publish a metric to CloudWatch using AWS embedded metrics format (AWS EMF) and emit
   * a debug-level log message
   * @param embeddedMetric an {@link EmbeddedMetric}
   * @optional
   * @param message Message to log, defaults to `"Publishing debug metric..."`
   * @param error Error to log. Optional.
   * @param context Additional context to add.
   * @see [AWS docs - _Specification: Embedded metric format_](https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch_Embedded_Metric_Format_Specification.html)
   * @see [`KatalLoggerCloudWatchBackend` README, search for "EMF"](https://code.amazon.com/packages/KatalLoggerCloudWatchBackend/blobs/mainline/--/README.md)
   * @example
   * ```ts
   * Logger.publishDebugMetric(
   *   new EmbeddedMetric("InitializedChatWithSupport", "Count", 1, {
   *     RefPage: state.chatSupportRefPage ?? "PageLevel",
   *   }),
   *   // The remaining parameters are for logging as usual
   *   "New hire initiated chat with support",
   *   undefined,
   *   {
   *     amazonUsername: state.amazonUsername,
   *     region: state.activeRegion,
   *     refPage: state.chatSupportRefPage ?? "PageLevel",
   *   }
   * );
   * ```
   */
  public static publishDebugMetric(
    embeddedMetric: EmbeddedMetric,
    message = "Publishing debug metric...",
    errorLike?: unknown,
    context?: Record<string, unknown>
  ): void {
    const emfPayload = embeddedMetric.toEmfPayload();
    const maybeError =
      errorLike instanceof Error ||
      axios.isAxiosError(errorLike) ||
      errorLike === undefined
        ? errorLike
        : new Error(JSON.stringify(errorLike));
    // eslint-disable-next-line testing-library/no-debugging-utils
    void Logger.debug(message, maybeError, {
      ...context,
      ...embeddedMetric.dimensions,
      sessionId: Logger.getSessionId(),
      emfLog: emfPayload,
    });
  }

  /**
   * Publish a metric to CloudWatch using AWS embedded metrics format (AWS EMF) and emit
   * an info-level log message
   * @param embeddedMetric an {@link EmbeddedMetric}
   * @optional
   * @param message Message to log, defaults to `"Publishing info metric..."`
   * @param error Error to log. Optional.
   * @param context Additional context to add.
   * @see [AWS docs - _Specification: Embedded metric format_](https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch_Embedded_Metric_Format_Specification.html)
   * @see [`KatalLoggerCloudWatchBackend` README, search for "EMF"](https://code.amazon.com/packages/KatalLoggerCloudWatchBackend/blobs/mainline/--/README.md)
   * @example
   * ```ts
   * Logger.publishInfoMetric(
   *   new EmbeddedMetric("InitializedChatWithSupport", "Count", 1, {
   *     RefPage: state.chatSupportRefPage ?? "PageLevel",
   *   }),
   *   // The remaining parameters are for logging as usual
   *   "New hire initiated chat with support",
   *   undefined,
   *   {
   *     amazonUsername: state.amazonUsername,
   *     region: state.activeRegion,
   *     refPage: state.chatSupportRefPage ?? "PageLevel",
   *   }
   * );
   * ```
   */
  public static publishInfoMetric(
    embeddedMetric: EmbeddedMetric,
    message = "Publishing info metric...",
    errorLike?: unknown,
    context?: Record<string, unknown>
  ) {
    const emfPayload = embeddedMetric.toEmfPayload();
    const maybeError =
      errorLike instanceof Error ||
      axios.isAxiosError(errorLike) ||
      errorLike === undefined
        ? errorLike
        : new Error(JSON.stringify(errorLike));
    void Logger.info(message, maybeError, {
      ...context,
      ...embeddedMetric.dimensions,
      sessionId: Logger.getSessionId(),
      emfLog: emfPayload,
    });
    void Logger.info(message, maybeError, {
      ...context,
      sessionId: Logger.getSessionId(),
    });
  }

  /**
   * Publish a metric to CloudWatch using AWS embedded metrics format (AWS EMF) and emit
   * a warn-level log message
   * @param embeddedMetric an {@link EmbeddedMetric}
   * @optional
   * @param message Message to log, defaults to `"Publishing warn metric..."`
   * @param error Error to log. Optional.
   * @param context Additional context to add.
   * @see [AWS docs - _Specification: Embedded metric format_](https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch_Embedded_Metric_Format_Specification.html)
   * @see [`KatalLoggerCloudWatchBackend` README, search for "EMF"](https://code.amazon.com/packages/KatalLoggerCloudWatchBackend/blobs/mainline/--/README.md)
   * @example
   * ```ts
   * Logger.publishWarnMetric(
   *   new EmbeddedMetric("InitializedChatWithSupport", "Count", 1, {
   *     RefPage: state.chatSupportRefPage ?? "PageLevel",
   *   }),
   *   // The remaining parameters are for logging as usual
   *   "New hire initiated chat with support",
   *   undefined,
   *   {
   *     amazonUsername: state.amazonUsername,
   *     region: state.activeRegion,
   *     refPage: state.chatSupportRefPage ?? "PageLevel",
   *   }
   * );
   * ```
   */
  public static publishWarnMetric(
    embeddedMetric: EmbeddedMetric,
    message = "Publishing warn metric...",
    errorLike?: unknown,
    context?: Record<string, unknown>
  ) {
    const emfPayload = embeddedMetric.toEmfPayload();
    const maybeError =
      errorLike instanceof Error ||
      axios.isAxiosError(errorLike) ||
      errorLike === undefined
        ? errorLike
        : new Error(JSON.stringify(errorLike));
    void Logger.warn(message, maybeError, {
      ...context,
      ...embeddedMetric.dimensions,
      sessionId: Logger.getSessionId(),
      emfLog: emfPayload,
    });
    void Logger.warn(message, maybeError, {
      ...context,
      sessionId: Logger.getSessionId(),
    });
  }

  /**
   * Publish a metric to CloudWatch using AWS embedded metrics format (AWS EMF) and emit
   * an error-level log message
   * @param embeddedMetric an {@link EmbeddedMetric}
   * @optional
   * @param message Message to log, defaults to `"Publishing error metric..."`
   * @param error Error to log. Optional.
   * @param context Additional context to add.
   * @see [AWS docs - _Specification: Embedded metric format_](https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch_Embedded_Metric_Format_Specification.html)
   * @see [`KatalLoggerCloudWatchBackend` README, search for "EMF"](https://code.amazon.com/packages/KatalLoggerCloudWatchBackend/blobs/mainline/--/README.md)
   * @example
   * ```ts
   * Logger.publishErrorMetric(
   *   new EmbeddedMetric("InitializedChatWithSupport", "Count", 1, {
   *     RefPage: state.chatSupportRefPage ?? "PageLevel",
   *   }),
   *   // The remaining parameters are for logging as usual
   *   "New hire initiated chat with support",
   *   undefined,
   *   {
   *     amazonUsername: state.amazonUsername,
   *     region: state.activeRegion,
   *     refPage: state.chatSupportRefPage ?? "PageLevel",
   *   }
   * );
   * ```
   */
  public static publishErrorMetric(
    embeddedMetric: EmbeddedMetric,
    message = "Publishing error metric...",
    errorLike?: unknown,
    context?: Record<string, unknown>
  ) {
    const emfPayload = embeddedMetric.toEmfPayload();
    const maybeError =
      errorLike instanceof Error ||
      axios.isAxiosError(errorLike) ||
      errorLike === undefined
        ? errorLike
        : new Error(JSON.stringify(errorLike));
    void Logger.error(message, maybeError, {
      ...context,
      ...embeddedMetric.dimensions,
      sessionId: Logger.getSessionId(),
      emfLog: emfPayload,
    });
    void Logger.error(message, maybeError, {
      ...context,
      sessionId: Logger.getSessionId(),
    });
  }

  /**
   * Publish a metric to CloudWatch using AWS embedded metrics format (AWS EMF) and emit
   * a fatal-level log message.
   * @param embeddedMetric an {@link EmbeddedMetric}
   * @optional
   * @param message Message to log, defaults to `"Publishing fatal metric..."`
   * @param error Error to log. Optional.
   * @param context Additional context to add.
   * @see [AWS docs - _Specification: Embedded metric format_](https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch_Embedded_Metric_Format_Specification.html)
   * @see [`KatalLoggerCloudWatchBackend` README, search for "EMF"](https://code.amazon.com/packages/KatalLoggerCloudWatchBackend/blobs/mainline/--/README.md)
   * @example
   * ```ts
   * Logger.publishFatalMetric(
   *   new EmbeddedMetric("InitializedChatWithSupport", "Count", 1, {
   *     RefPage: state.chatSupportRefPage ?? "PageLevel",
   *   }),
   *   // The remaining parameters are for logging as usual
   *   "New hire initiated chat with support",
   *   undefined,
   *   {
   *     amazonUsername: state.amazonUsername,
   *     region: state.activeRegion,
   *     refPage: state.chatSupportRefPage ?? "PageLevel",
   *   }
   * );
   * ```
   */
  public static publishFatalMetric(
    embeddedMetric: EmbeddedMetric,
    message = "Publishing fatal metric...",
    errorLike?: unknown,
    context?: Record<string, unknown>
  ) {
    const emfPayload = embeddedMetric.toEmfPayload();
    const maybeError =
      errorLike instanceof Error ||
      axios.isAxiosError(errorLike) ||
      errorLike === undefined
        ? errorLike
        : new Error(JSON.stringify(errorLike));
    void Logger.fatal(message, maybeError, {
      ...context,
      sessionId: Logger.getSessionId(),
      emfLog: emfPayload,
    });
    void Logger.fatal(message, maybeError, {
      ...context,
      sessionId: Logger.getSessionId(),
    });
  }
}
