import {
  IconAlertTriangleFill,
  IconSize,
} from "@amzn/stencil-react-components/icons";
import { Row, View } from "@amzn/stencil-react-components/layout";
import {
  MessageBanner,
  MessageBannerType,
} from "@amzn/stencil-react-components/message-banner";
import React, { Children, ReactNode } from "react";

/**
 * An error message banner that will be used to notify about the issues occurred during the onboarding process.
 */
export default function ErrorBanner(props: {
  children: ReactNode;
}): JSX.Element {
  // If there is more than one message, bold the first one
  const boldFirst = Children.count(props.children) > 1;

  return (
    <MessageBanner
      type={MessageBannerType.Warning}
      dataTestId="idCheckErrorMsg"
      icon={
        <IconAlertTriangleFill dataTestId="iconAlertTriangleFill" width={16} />
      }
    >
      {Children.map(props.children, (c, i) =>
        boldFirst && i == 0 ? <b>{c} </b> : c
      )}
    </MessageBanner>
  );
}
