import { View } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { StencilThemeFont } from "@amzn/stencil-react-theme-default";
import styled from "@emotion/styled";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Props } from "react-intl/src/components/message";

/**
 * ordered list item with pre-configured style
 */
const ListItem = styled.li`
  line-height: 2;
`;

const List = styled.ol`
  list-style-position: outside;
  padding-left: 15px;
`;

interface ListProps {
  /**
   * A intl format messages props list.
   */
  formatMessagesListProps: Props[];

  /**
   * The data-test-id that will be used for testing
   */
  dataTestId?: string;

  /**
   * The font size of the text displayed in the ordered list
   */
  fontSize?: keyof StencilThemeFont["size"] | string | number;

  /**
   * The font weight of the text displayed in the ordered list
   */
  fontWeight: keyof StencilThemeFont["fontWeight"] | number;
}

/**
 * An ordered list.
 * @param formatMessagesListProps A intl format messages props list.
 * @param dataTestId The data-test-id that will be used for testing
 * @param fontSize The font size of the text displayed in the ordered list
 * @param fontWeight The font weight of the text displayed in the ordered list
 */
export const OrderedList = ({
  formatMessagesListProps,
  dataTestId,
  fontSize,
  fontWeight,
}: ListProps): JSX.Element => {
  return (
    <View padding={{ left: 28 }}>
      <List data-test-id={dataTestId}>
        {formatMessagesListProps.map(({ id, values }) => (
          <Text key={id} fontSize={fontSize} fontWeight={fontWeight}>
            <ListItem>
              <FormattedMessage id={id} values={values} />
            </ListItem>
          </Text>
        ))}
      </List>
    </View>
  );
};

OrderedList.defaultProps = {
  fontSize: "T300",
  fontWeight: "light",
};
