import {
  GetUserAliasResponse400,
  GetUserProgressResponse,
} from "@amzn/client-workbench-api-model";
import axios, { AxiosError } from "axios";
import dayjs, { Dayjs } from "dayjs";
import { useContext, useEffect, useState } from "react";
import { UseQueryResult } from "react-query";

import { BLOCKING_ERROR_TYPE } from "@/apis/constants";
import { VerificationStatus } from "@/apis/IdentityCheckHelper";
import { Logger } from "@/apis/Logger";
import { isDayOneForbidden } from "@/components/IdentityCheck/helper/rivHelper";
import { trackStructEventOnceAppSession } from "@/helpers/trackStructEventOnceAppSession";
import { useAmazonAlias } from "@/hooks/useAmazonAlias";
import { useUserProgress } from "@/hooks/useUserProgress";
import { AppContext } from "@/stores/appStore";
import { ActionType } from "@/stores/constants";
import { Day1EventAction, Day1EventCategory } from "@/types/snowplow-events";

/**
 * Check if the current date is earlier than the NH's actual start date.
 */
const isEarlyAccess = (
  userProgress: GetUserProgressResponse
): {
  isEarlyAccessForbidden: boolean;
  candidateLocalStartDate?: Dayjs;
} => {
  if (userProgress.candidateStartDate) {
    const candidateLocalStartDate = dayjs.tz(
      userProgress.candidateStartDate,
      dayjs.tz.guess() // Get the user current timezone
    );
    const isEarlyThanStartDate = dayjs().isBefore(candidateLocalStartDate);

    return {
      isEarlyAccessForbidden:
        isDayOneForbidden(userProgress.verificationStatus) &&
        isEarlyThanStartDate,
      candidateLocalStartDate: candidateLocalStartDate,
    };
  } else {
    return {
      isEarlyAccessForbidden: false,
    };
  }
};

/**
 * Return true is the error is a 404 NOT_FOUND error. Otherwise, return false.
 * @param error The error instance from calling the CWB API.
 */
const isNotFound = (error: AxiosError | Error) => {
  return (
    axios.isAxiosError(error) &&
    (error.response?.data as GetUserAliasResponse400)?.code === "NOT_FOUND"
  );
};

export type UseCheckReqsResult = Pick<
  UseQueryResult<VerificationStatus>,
  "isFetched" | "isError"
> & {
  /** The local NH's local start date*/
  candidateLocalStartDate?: Dayjs;

  /** The blocking error type on the Day1 */
  blockingErrorType?: BLOCKING_ERROR_TYPE;
};

/**
 * Setup Amplify and check all requirements needed for NHs to start their day1 on-boarding.
 * TODO: Check to see if a preference was set for Manual or Automated RIV on PD1 and then set the wizard state accordingly.
 */
export const useCheckReqs = (): UseCheckReqsResult => {
  /** Check if NHs already has an alias assigned to him/her */
  const {
    isError: isUserAliasError,
    isFetched: isUserAliasFetched,
    error: amazonUserAliasError,
  } = useAmazonAlias();

  /**
   * Make sure that NHs are eligible to start their onboarding process. Getting an error from calling the userProgress
   * api should indicate that they are not eligible to start the onboarding process.
   * TODO: This should be expanded to include the preference for RIV path.
   */
  const {
    data: userProgress,
    isError: isUserProgressError,
    isFetched: isUserProgressFetched,
  } = useUserProgress();

  const isAllFetched = isUserAliasFetched && isUserProgressFetched;
  const [isError, setIsError] = useState(false);
  const [candidateLocalStartDate, setCandidateLocalStartDate] =
    useState<Dayjs>();
  const [blockingErrorType, setBlockingErrorType] =
    useState<BLOCKING_ERROR_TYPE>();

  const { dispatch } = useContext(AppContext);

  /**
   * Check the requirements for continuing the onboarding process and update the loading and error states accordingly.
   */
  useEffect(() => {
    if (isAllFetched) {
      const isForbidden = userProgress
        ? isDayOneForbidden(userProgress.verificationStatus)
        : false;
      const { isEarlyAccessForbidden, candidateLocalStartDate } = userProgress
        ? isEarlyAccess(userProgress)
        : { isEarlyAccessForbidden: false, candidateLocalStartDate: undefined };
      setCandidateLocalStartDate(candidateLocalStartDate);

      const noStartDate =
        userProgress !== undefined && !userProgress.candidateStartDate;
      const isError =
        isUserAliasError ||
        isUserProgressError ||
        isEarlyAccessForbidden ||
        isForbidden ||
        noStartDate;
      setIsError(isError);

      if (isError) {
        if (isEarlyAccessForbidden) {
          void Logger.warn(
            "Blocking error - attempting to access the Day One Onboarding Wizard before Day One",
            undefined,
            { ...userProgress, candidateLocalStartDate }
          );
          setBlockingErrorType(BLOCKING_ERROR_TYPE.BeforeDay1);
          trackStructEventOnceAppSession({
            category: Day1EventCategory.BlockingError,
            action: Day1EventAction.NotDay1,
            value: candidateLocalStartDate
              ? parseInt(candidateLocalStartDate.format("YYYYMMDD"))
              : undefined,
          });
          dispatch({
            type: ActionType.SET_NON_DIRECTED_CHAT_SUPPORT_CONTEXT,
            nonDirectedChatSupportContext: "BLOCKED_BEFORE_DAY_1",
          });
        } else if (noStartDate) {
          void Logger.warn(
            "Blocking error - new hire has no start date",
            undefined,
            { ...userProgress, candidateLocalStartDate }
          );
          setBlockingErrorType(BLOCKING_ERROR_TYPE.NoStartDate);
          trackStructEventOnceAppSession({
            category: Day1EventCategory.BlockingError,
            action: Day1EventAction.NoStartDate,
          });
          dispatch({
            type: ActionType.SET_NON_DIRECTED_CHAT_SUPPORT_CONTEXT,
            nonDirectedChatSupportContext: "BLOCKED_NO_START_DATE",
          });
        } else if (isUserProgressError || isForbidden) {
          void Logger.warn(
            "Blocking error - new hire progress error or is forbidden from Day One onboarding wizard experience",
            undefined,
            { ...userProgress, candidateLocalStartDate }
          );
          setBlockingErrorType(BLOCKING_ERROR_TYPE.NotDay1);
          trackStructEventOnceAppSession({
            category: Day1EventCategory.BlockingError,
            action: Day1EventAction.GenericError,
          });
          dispatch({
            type: ActionType.SET_NON_DIRECTED_CHAT_SUPPORT_CONTEXT,
            nonDirectedChatSupportContext: "BLOCKED_NOT_DAY_1",
          });
        } else if (amazonUserAliasError && isNotFound(amazonUserAliasError)) {
          void Logger.warn(
            "Blocking error - new hire as an issue with their Amazon user alias",
            amazonUserAliasError instanceof Error ||
              axios.isAxiosError(amazonUserAliasError)
              ? amazonUserAliasError
              : undefined,
            { ...userProgress, candidateLocalStartDate }
          );
          setBlockingErrorType(BLOCKING_ERROR_TYPE.NoAlias);
          trackStructEventOnceAppSession({
            category: Day1EventCategory.BlockingError,
            action: Day1EventAction.AccountIssue,
          });
          dispatch({
            type: ActionType.SET_NON_DIRECTED_CHAT_SUPPORT_CONTEXT,
            nonDirectedChatSupportContext: "BLOCKED_NO_ALIAS",
          });
        } else {
          void Logger.warn(
            "Blocking error - generic blocking error",
            undefined,
            { ...userProgress, candidateLocalStartDate }
          );
          setBlockingErrorType(BLOCKING_ERROR_TYPE.Generic);
          trackStructEventOnceAppSession({
            category: Day1EventCategory.BlockingError,
            action: Day1EventAction.GenericError,
          });
          dispatch({
            type: ActionType.SET_NON_DIRECTED_CHAT_SUPPORT_CONTEXT,
            nonDirectedChatSupportContext: "BLOCKED_GENERIC",
          });
        }
      }
    }
  }, [
    amazonUserAliasError,
    isUserAliasError,
    isUserProgressError,
    userProgress,
    isAllFetched,
  ]);

  return {
    isError: isError,
    isFetched: isAllFetched,
    blockingErrorType: blockingErrorType,
    candidateLocalStartDate: candidateLocalStartDate,
  };
};
