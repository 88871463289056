import { useContext, useMemo } from "react";

import { AppContext } from "../stores";

/**
 * Translate the current hiring country to the
 * region code.
 *
 * Ex. FIN -> EU
 *
 * If the country is not associated with a region
 * the country code is returned
 *
 * If the hiring country is undefined, an empty string is
 * returned.
 *
 * @param region
 * @returns
 */
export const useHiringRegion = (): string => {
  const { state } = useContext(AppContext);
  return useMemo(() => {
    if (state.hiringCountry && state.hiringCountry in REGION_MAP) {
      return REGION_MAP[state.hiringCountry as RegionalizedCountry];
    }
    if (!state.hiringCountry) {
      return "";
    }
    return state.hiringCountry;
  }, [state.hiringCountry]);
};

/**
 * All the countries that belong to the EU region
 */
type EUCountries =
  | "AUT"
  | "BEL"
  | "HRV"
  | "CZE"
  | "DNK"
  | "EST"
  | "FIN"
  | "FRA"
  | "DEU"
  | "GRC"
  | "HUN"
  | "IRL"
  | "ITA"
  | "LUX"
  | "NLD"
  | "NOR"
  | "POL"
  | "PRT"
  | "ROU"
  | "SVK"
  | "ESP"
  | "SWE"
  | "CHE"
  | "TUR"
  | "GBR";

type EURegion = "EU";

type RegionalizedCountry = EUCountries;

type Regions = EURegion;

type RegionMap = Record<RegionalizedCountry, Regions>;

const REGION_MAP: RegionMap = {
  AUT: "EU",
  BEL: "EU",
  HRV: "EU",
  CZE: "EU",
  DNK: "EU",
  EST: "EU",
  FIN: "EU",
  FRA: "EU",
  DEU: "EU",
  GRC: "EU",
  HUN: "EU",
  IRL: "EU",
  ITA: "EU",
  LUX: "EU",
  NLD: "EU",
  NOR: "EU",
  POL: "EU",
  PRT: "EU",
  ROU: "EU",
  SVK: "EU",
  ESP: "EU",
  SWE: "EU",
  CHE: "EU",
  TUR: "EU",
  GBR: "EU",
};
